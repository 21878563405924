import headingTitleImg from "./assets/heading-title.png";
import { useLocation } from "react-router-dom";
export default function HeadingTitle() {
  const location = useLocation();
  return (
    <div
      style={{
        marginTop: "20%",
		paddingLeft: location.pathname==="/register" ? "60px" : null,
        width: "80%",
      }}
    >
      <div
        style={{
          width: "80%",
          minWidth: "50px",
          maxWidth: "340px",
        }}
      >
        <img
          alt="Start A healthy New year"
          src={headingTitleImg}
          style={{
            minWidth: "180px",

            width: "100%",
            height: "auto",
          }}
        />
      </div>
    </div>
  );
}
