import { Modal, Button, Form, Input } from "antd";
import spay from "../../images/spay.png";
import axiosClient from "../../utils/axiosClient";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const SpayDetails = ({ payment, setPayment, selectedReceipt,
  // setRegister,
  // register 
}) => {
  const [form] = Form.useForm();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);

  const regexName = /^[a-zA-Z\s]+$/;
  const regexUsername = /^[a-zA-Z0-9_]+$/;
  const regexPassword = /^\d{12}$/;
  const regexPhoneNumber = /^(601[0-9]{8,9})$/;

  const handleSubmit = async (values) => {
    // console.log("values", values);
    try {
      if (!values) {
        setError(t("Please fill up all the required fields"));
        setTimeout(() => {
          setError(null);
        }, 1000);
      }

      const res = await axiosClient.post("/payment/create", {
        id: selectedReceipt.rewardInstanceId,
        value: selectedReceipt.rewardInstanceId.value,
        details: values,
        type: "SPAY",
        campaignEntryId: selectedReceipt.sourceId,
      });

      if (res && res.data) {
        setSuccess(res.data.message);
        // setRegister(true);
        // console.log("SPAY", register);
        setTimeout(() => {
          setPayment(false);
          form.resetFields();
          setSuccess(null);
        }, 1000);
      }
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
      // message.error(error.response.data.message);
      setTimeout(() => {
        setError(null);
      }, 1000);
    }
  };

  return (
    <div>
      <Modal
        open={payment}
        centered
        footer={null}
        closable={true}
        width={350}
        maskClosable={false}
        onCancel={() => setPayment(false)}
      >
        <div className="text-center">
          <img style={{ width: "28%" }} src={spay} alt={"spay"} />
          <div style={{ lineHeight: "25px" }}>
            <h3 className="text-center font-weight-700 font-20">
              {t('PaymentDetails')}
            </h3>
            <p className="text-center font-14 font-weight-400">
              {t('SPAYdesc')}
            </p>
          </div>

          <Form
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={(e) => console.error("submit fail", e.errorFields)}
            autoComplete="off"
            scrollToFirstError
          >
            <div>
              <Form.Item
                name="username"
                hasFeedback
                validateDebounce={500}
                className="payment-details-error-message"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                  {
                    pattern: regexUsername,
                    message: "No symbols allowed!",
                  },
                ]}
                style={{ marginBottom: 10 }}
              >
                <Input
                  type="text"
                  name="username"
                  placeholder={t('SPAYusername')}
                  className="font-12 font-weight-400"
                  required
                  style={{
                    width: "100%",
                    height: "50px",
                    borderRadius: "10px",
                  }}
                />
              </Form.Item>
              <Form.Item
                name="name"
                className="payment-details-error-message"
                hasFeedback
                validateDebounce={500}
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                  {
                    pattern: regexName,
                    message:
                      t("Invalid name format! Only letters and spaces allowed."),
                  },
                ]}
                style={{ marginBottom: 10 }}
              >
                <Input
                  type="text"
                  name="name"
                  placeholder={t('SPAYfullName')}
                  className="font-12 font-weight-400"
                  style={{
                    width: "100%",
                    height: "50px",
                    borderRadius: "10px",
                  }}
                  required
                />
              </Form.Item>
              <Form.Item
                name="icNo"
                hasFeedback
                validateDebounce={500}
                className="payment-details-error-message"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                  {
                    pattern: regexPassword,
                    message: t("Invalid IC format! Must be 12 digits"),
                  },
                ]}
                style={{ marginBottom: 10 }}
              >
                <Input
                  type="number"
                  name="icNo"
                  placeholder={t('SPAYic')}
                  className="font-12 font-weight-400"
                  style={{
                    width: "100%",
                    height: "50px",
                    borderRadius: "10px",
                  }}
                />
              </Form.Item>
              <Form.Item
                name="phoneNumber"
                className="payment-details-error-message"
                hasFeedback
                validateDebounce={500}
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                  {
                    pattern: regexPhoneNumber,
                    message: t("Invalid phone number format! Starts with 60"),
                  },
                ]}
                style={{ marginBottom: 10 }}
              >
                <Input
                  type="tel"
                  name="phoneNumber"
                  placeholder={t('SPAYphone')}
                  className="font-12 font-weight-400"
                  style={{
                    width: "100%",
                    height: "50px",
                    borderRadius: "10px",
                  }}
                />
              </Form.Item>
            </div>

            <div
              style={{ color: error ? "red" : "green" }}
              className="font-12 text-center"
            >
              {error ? error : success}
            </div>

            <Form.Item>
              <Button
                htmlType="submit"
                block
                // loading={loading}
                style={{
                  fontSize: "18px",
                  borderRadius: "5px",
                  background: "#CB0E07",
                  color: "white",
                  border: "none",
                  fontWeight: "700",
                  height: "40px",
                  width: "100%",
                }}
                className="mt-2"
              >
                {t('Register')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default SpayDetails;
