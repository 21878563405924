import { Modal, Button } from "antd";
import tng from "../../images/tng.png";
import grab from "../../images/displayGrab.png";
import { useTranslation } from "react-i18next";
const HowToRedeem = ({ redeem, setRedeem, type }) => {
  const {t} = useTranslation();

  return (
    <div>
      {type === "TNG" || type === "GRABPAY" ? (
        <Modal
          open={redeem}
          centered
          footer={null}
          closable={false}
          width={350}
        >
          <div className="text-center">
            <img
              style={{ width: "28%" }}
              src={type === "TNG" ? tng : type === "GRABPAY" ? grab : null}
              alt={type || null}
            />
            <div className="mt-1" style={{ lineHeight: "25px" }}>
              {type === "TNG" && (
                <h3
                  style={{
                    fontSize: "20px",
                    width: "auto",
                  }}
                  className="text-center"
                >
                  {t('HowToRedeem_TNG_title')}
                </h3>
              )}
              {type === "GRABPAY" && (
                <h3
                  style={{
                    fontSize: "20px",
                    width: "auto",
                  }}
                  className="text-center"
                >
                  {t('HowToRedeem_GRAB_title')}
                </h3>
              )}
            </div>
            <div className="mt-2">
              {type === "GRABPAY" && (
                <ol
                  style={{
                    padding: "0 15px",
                    fontSize: "14px",
                    textAlign: "justify",
                  }}
                >
                  <li>{t("Open the Grab app and choose the “Gifts” Tab.")}</li>
                  <li>{t("Click the Gifts icon at the upper right corner")}</li>
                  <li>{t("Key-in your Gift Code")}</li>
                  <li>
                    {t("Click Pick Your Gift, then proceed with saving the voucher")}
                  </li>
                  <li>{t("Choose the gift")}</li>
                  <li>{t("Your saved voucher will be stored in “My Rewards”")}</li>
                </ol>
              )}

              {type === "TNG" && (
                <ol
                  style={{
                    padding: "0 15px",
                    fontSize: "12px",
                    textAlign: "justify",
                  }}
                >
                  <li>{t("Open the Touch 'n Go eWallet Reload PIN App")}</li>
                  <li>{t("Click 'Reload'")}</li>
                  <li>{t("Click 'eWallet Balance'")}</li>
                  <li>{t("Click on ‘TNG eWallet Reload PIN below of the page")}</li>
                  <li>
                    {t("Key in the TNG eWallet Reload PIN and click 'Reload Now'")}
                  </li>
                  <li>{t("Successfully redeem Touch 'n Go eWallet voucher")}</li>
                </ol>
              )}
            </div>
            <Button
              type="primary"
              className="mt-1"
              onClick={() => setRedeem(false)}
              style={{
                fontSize: type === "GRABPAY" ? "14px" : "18px",
                borderRadius: "5px",
                background: "#CB0E07",
                color: "white",
                border: "none",
                fontWeight: type === "GRABPAY" ? "700" : "500",
                height: "40px",
                width: "90%",
              }}
            >
              {type === "GRABPAY" && "CLOSE"}
              {type === "TNG" && "Sounds Good!"}
            </Button>
          </div>
        </Modal>
      ) : 
      
   null}
    </div>
  );
};

export default HowToRedeem;
