import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  ENG: {
    translation: {
      "Log In": "Log In",
      "Key in Phone Number to Login now!": "Key in Phone Number to Login now!",
      "Your Phone Number": "Your Phone Number",
      "SEND OTP CODE": "SEND OTP CODE",
      "OTP Verification": "OTP Verification",
      "Please type verification code sent to":
        "Please type verification code sent to",
      "Didnt't get OTP?": "Didn't get OTP?",
      "Invalid phone number format! Starts with 60":
        "Invalid phone number format! Starts with 60",
      "Resend OTP": "Resend OTP",
      PaymentDetails: "Payment Details",
      Verify: "VERIFY",
      Registration: "Registration",
      FullName: "Full Name",
      MobileNumber: "Mobile Number",
      Email: "Email",
      "I agree to Haleon's": "I agree to Haleon's",
      TnC: "Terms and Conditions",
      PrivacyPolicy: "Privacy Policy",
      "and the": "and the",
      statement:
        "I authorize Haleon to disclose my personal data* to selected third parties to deliver value added services to me as a Haleon Rewards member.",
      Register: "Register",
      Successful: "Successful!",
      "Registration Successful": "Registration Successful",
      Done: "DONE",
      Questionnaire:
        "Have you purchased any Haleon products in the past 6 months?",
      Yes: "Yes",
      No: "No",
      Submit: "Submit",
      // "Upload purchase receipt to":"Upload purchase receipt to",
      // "receive rewards!":"receive rewards!",
      UploadReceipt: "Upload Receipt",
      UploadSuccess:
        "Your receipt has been successfully uploaded and will be validated within 3 working days.",
      Rewards: "View Rewards",
      "Terms & Conditions": "Terms & Conditions",
      EVoucher: "E-VOUCHER",
      CampaignPeriod: "Campaign Period",
      January: "January",
      February: "February",
      "Min Purchase of": "Min Purchase of",
      RedeemNow: "REDEEM NOW",
      "Upload receipt photo": "Upload receipt photo",
      "Supported image format": "*Supported image format: JPEG, JPG, PNG only",
      notes:
        "Notes: Your receipt will be validated after receipt submission in 3 working days",
      "Select your reward": "Select your reward",
      "Submit Receipt": "Submit Receipt",
      "Success!": "Success!",
      "Your receipt has been successfully uploaded and will be validated within 3 working days.":
        "Your receipt has been successfully uploaded and will be validated within 3 working days.",
      StayTuned: "STAY TUNED",
      "Something exciting is on the way.": "Something exciting is on the way",
      "Stay Tuned for upcoming exciting rewards":
        "Stay Tuned for upcoming exciting rewards",
      HowToRedeem: "How to redeem?",
      "Redeem Now": "Redeem Now",
      "exp. March 31": "exp. March 31",
      Back: "Back",
      SPAY_AfterRedeem:
        "The E-Credit will be deposited into your SPay account, and you will receive it within 3 working days.",
      "My Rewards": "My Rewards",
      "My Submissions": "My Submissions",
      Rejected: "Rejected",
      Approved: "Approved",
      Pending: "Pending",
      DateSubmitted: "Date Submitted",
      ReceiptDate: "Receipt Date",
      ReceiptAmount: "Receipt Amount",
      ReceiptNumber: "Receipt Number",
      Later: "Later",
      UploadAgain: "Upload Again",
      "Your submission have been rejected":
        "Your submission have been rejected",
      "Kindly upload your receipt again": "Kindly upload your receipt again",
      HowToRedeem_TNG_title: "How to Redeem Your Touch 'n Go Reload PIN?",
      "Open the Touch 'n Go eWallet Reload PIN App":
        "Open the Touch 'n Go eWallet Reload PIN App",
      "Click 'Reload'": "Click 'Reload'",
      "Click 'eWallet Balance'": "Click 'eWallet Balance'",
      "Click on ‘TNG eWallet Reload PIN below of the page":
        "Click on ‘TNG eWallet Reload PIN below of the page",
      "Key in the TNG eWallet Reload PIN and click 'Reload Now'":
        "Key in the TNG eWallet Reload PIN and click 'Reload Now'",
      "Successfully redeem Touch 'n Go eWallet voucher":
        "Successfully redeem Touch 'n Go eWallet voucher",
      HowToRedeem_GRAB_title: "How to Redeem Your GrabGifts?",
      "Open the Grab app and choose the “Gifts” Tab.":
        "Open the Grab app and choose the “Gifts” Tab.",
      "Click the Gifts icon at the upper right corner":
        "Click the Gifts icon at the upper right corner",
      "Key-in your Gift Code": "Key-in your Gift Code",
      "Click Pick Your Gift, then proceed with saving the voucher":
        "Click Pick Your Gift, then proceed with saving the voucher",
      "Choose the gift": "Choose the gift",
      "Your saved voucher will be stored in “My Rewards”":
        "Your saved voucher will be stored in “My Rewards”",
      TNG_TNC_I: "Only ONE (1) Reload PIN Reward can be used per reload.",
      TNG_TNC_II:
        "To utilize the Reload PIN, log in on the microsite. At `Rewards`, tap on the ‘View Now’ button under your rewards. Then, tap on the copy icon next to the Voucher Code of the respective rewards and follow the steps provided.",
      TNG_TNC_III:
        "Used Reload PIN Reward are non-reversible. Once used, the amount is not exchangeable nor refundable for credits, cash or such replacement in part or in full.",
      TNG_TNC_IV:
        "TNG Digital will not in any way contact users for any personal information or account credentials for this Campaign.",
      TNG_TNC_V: "Reload PIN will be expired on",
      "31 Mar 2024": "31 Mar 2024",
      GRAB_TNC_I:
        "Users must enter promo code at checkout to be entitled to the discount.",
      GRAB_TNC_II:
        "This promo code is valid for (1) ONE order from services on GrabFood, GrabRide, GrabExpress or GrabMart.",
      GRAB_TNC_III: "This promo code will be expired on",
      "31 May 2024": "31 May 2024",
      GRAB_TNC_IV:
        "Promotion is not valid with any other vouchers or promotions.",
      GRAB_TNC_V:
        "Grab reserves the right to alter, extend or terminate the promotion, or amend the terms and conditions at its solediscretion at any time without prior notice. In case of any disputes directly or indirectly arising from the promotion, the decision of Grab shall be final.",
      GRAB_TNC_VI:
        "These terms and conditions shall be governed by the laws of Malaysia and any dispute arising out of or in connection with promotion shall be referred to the exclusive jurisdiction of courts of Malaysia.",
      SPAY_TNC_I: "User must provide the details as below:",
      SPAY_username: "SPayGlobal username / ID",
      SPAY_fullName: "Full Name",
      SPAY_NRIC: "IC number",
      SPAY_phone: "Phone number (registered with SPayGlobal)",
      SPAY_TNC_II:
        " It will be direct to the user wallet, please make sure your wallet is not maximum of the amount of the wallet RM3,000.",
      SPAY_TNC_III:
        "SPAY Global shall have the sole and absolute discretion whether to provide you with the Services or not, herein.",
      SPAY_TNC_IV:
        "Kindly allow at least 7 working days for Sarawak Pay to direct debit the rewards to user’s wallet.",
      "Copy to Clipboard": "Copy to Clipboard",
      Close: "Close",
      "Sounds Good!": "Sounds Good!",
      Hello: "Hello",
      "RELOAD PIN": "RELOAD PIN",
      Receipt: "Receipt",
      TotalAmount: "Total Amount",
      ReceiptPlaceholder: "Total Amount(RM)",
      TNG880: "Touch n go eWallet Reload PIN valued at RM8.80.",
      TNG1680: "Touch n go eWallet Reload PIN valued at RM16.80.",
      TNG5: "Touch n go eWallet Reload PIN valued at RM5.",
      GRAB5: "GrabGifts valued at RM5.",
      SPAY5: "SPay E-Credit worth RM5",
      promoterCode: "Promoter Code",
      SPAYdesc: "Please provide your SPay details to receive the reward",
      SPAYusername: "SPayGlobal Username / ID",
      SPAYfullName: "Full Name (as in the ID card)",
      SPAYic: "Identification Number",
      SPAYphone: "Mobile Number(registered with SPayGlobal)",

      // FE: validation error messages
      "Invalid name format!": "Invalid name format!",
      "Invalid phone number format!": "Invalid phone number format!",
      "Invalid email format!": "Invalid email format!",
      "Invalid promoter code": "Invalid promoter code",
      "Promoter id not found": "Promoter id not found",
      "Only numbers and alphabets are allowed.":
        "Only numbers and alphabets are allowed.",
      "Campaign not started yet.": "Campaign not started yet.",
      "Campaign has ended.": "Campaign has ended.",
      "Campaign not available for your channel. Will redirect to you campaign page.":
        "Campaign not available for your channel. Will redirect to you campaign page.",
      "You're not eligible for this campaign. you'll be redirected to your campaign page.":
        "You're not eligible for this campaign. you'll be redirected to your campaign page.",
      "You have reached the maximum total claims":
        "You have reached the maximum total claims",
      "Missing required fields": "Missing required fields",
      "Please select at least one product!":
        "Please select at least one product!",
      "Please select an option first": "Please select an option first",
      "An error occurred while processing data":
        "An error occurred while processing data",
      "Could not find reward for this receipt amount. Make sure to submit the correct receipt amount.":
        "Could not find reward for this receipt amount. Make sure to submit the correct receipt amount.",
      "Please upload the receipt image": "Please upload the receipt image",
      "No Receipt Submission Yet": "No Receipt Submission Yet",
      "No Rewards Yet": "No Rewards Yet",
      "User has filled up the payment information ":
        "User has filled up the payment information ",
      "Please fill up all the required fields":
        "Please fill up all the required fields",
      "Invalid IC format! Must be 12 digits":
        "Invalid IC format! Must be 12 digits",
      "This field is required": "This field is required",
      "user missing registerSource channel":
        "user missing registerSource channel",
      "Submission Update": "Submission Update",
      "Reject Update":
        "Great effort! You`ve hit your monthly submission limit, but we noticed a few tweaks needed for one or more receipts. Click the `Resubmit Now` button to replace these receipts.",
      "Resubmit Now": "Resubmit Now",
    },
  },
  BM: {
    translation: {
      "Log In": "Log Masuk",
      "Key in Phone Number to Login now!":
        "Masukkan Nombor Telefon Bimbit untuk Log Masuk sekarang!",
      "Your Phone Number": "Nombor Telefon Bimbit Anda",
      "Invalid phone number format! Starts with 60":
        "Format nombor telefon tidak sah! Bermula dengan 60",
      "SEND OTP CODE": "Hantar Kod OTP",
      "OTP Verification": "Pengesahan OTP",
      "Please type verification code sent to":
        "Sila taip kod pengesahan yang dihantar ke",
      "Didnt't get OTP?": "Tidak menerima OTP?",
      "Resend OTP": "Hantar semula OTP",
      Verify: "SAHKAN",
      Registration: "Pendaftaran",
      FullName: "Nama Penuh",
      MobileNumber: "Nombor Telefon Bimbit",
      Email: "E-mel",
      "I agree to Haleon's": "Saya bersetuju dengan",
      TnC: "Terma dan Syarat",
      PrivacyPolicy: "Polisi Privasi",
      "and the": "serta",
      backUP:
        "Saya bersetuju dengan Terma dan Syarat Haleon serta Polisi Privasi",
      statement:
        "Saya memberi kuasa kepada Haleon untuk mendedahkan data peribadi saya* kepada pihak ketiga terpilih untuk menyampaikan perkhidmatan tambah nilai kepada saya sebagai ahli Haleon Rewards.",
      Register: "Daftar",
      Successful: "Berjaya!",
      "Registration Successful": "Pendaftanran Berjaya",
      Done: "SELESAI",
      Questionnaire:
        "Pernahkah anda membeli sebarang produk Haleon dalam tempoh 6 bulan yang lepas?",
      Yes: "Ya",
      No: "Tidak",
      Submit: "Hantar",
      // "Upload purchase receipt to\nreceive rewards!":
      //   "Muat naik resit pembelian untuk menerima ganjaran!",
      UploadReceipt: "Muat Naik Resit",
      Rewards: "Ganjaran",
      "Terms & Conditions": "Terma & Syarat",
      EVoucher: "KREDIT E-WALLET",
      CampaignPeriod: "Tempoh Kempen",
      January: "Januari",
      February: "Februari",
      "Min Purchase of": "Pembelian Min",
      RedeemNow: "TERUS SEKARANG",
      "Upload receipt photo": "Muat naik gambar resit",
      "Supported image format":
        "*Format gambar yang disokong: JPEG, JPG, PNG sahaja",
      notes:
        "Nota: Resit anda akan disahkan dalam tempoh 3 hari bekerja selepas penyerahan resit",
      "Select your reward": "Pilih ganjaran anda",
      "Submit Receipt": "Hantar Resit",
      "Your receipt has been successfully uploaded and will be validated within 3 working days.":
        "Resit anda telah berjaya dimuat naik dan akan disahkan dalam tempoh 3 hari bekerja.",
      StayTuned: "SILA NANTIKAN",
      "Something exciting is on the way.": "Sesuatu yang menarik menanti anda",
      "Stay Tuned for upcoming exciting rewards":
        "Nantikan ganjaran menarik yang akan datang",
      HowToRedeem: "Bagaimana cara menebus?",
      "Redeem Now": "Tebus Sekarang",
      "exp. March 31": "Luput 31 hb Mac",
      Back: "KEMBALI",
      SPAY_AfterRedeem:
        "E-Kredit akan didepositkan ke dalam akaun SPay anda, dan anda akan menerimanya dalam tempoh 3 hari hari bekerja.",
      "My Rewards": "Ganjaran Saya",
      "My Submissions": "Penyerahan Saya",
      Rejected: "Ditolak",
      Approved: "Diluluskan",
      Pending: "Ditangguh",
      DateSubmitted: "Tarikh dihantar",
      ReceiptDate: "Tarik resit",
      ReceiptAmount: "Jumlah resit",
      ReceiptNumber: "Resit nombor",
      Later: "Kemudian",
      UploadAgain: "Muat Naik Semula",
      "Your submission have been rejected": "Penyerahan anda telah ditolak",
      "Kindly upload your receipt again":
        "Sila muat naik resit anda sekali lagi​",
      HowToRedeem_TNG_title:
        "Bagaimana untuk Menebus PIN Tambah Nilai Touch ‘n Go eWallet Anda?",
      "Open the Touch 'n Go eWallet Reload PIN App":
        "Buka aplikasi PIN Tambah Nilai Touch ‘n Go eWallet.​",
      "Click 'Reload'": "​Klik pada 'Tambah Nilai'.",
      "Click 'eWallet Balance'": "Pilih 'Baki eWallet'.",
      "Click on ‘TNG eWallet Reload PIN below of the page":
        "Skrol ke bawah dan klik pada 'PIN Tambah Nilai Touch ‘n Go eWallet'.",
      "Key in the TNG eWallet Reload PIN and click 'Reload Now'":
        "Masukkan PIN Tambah Nilai Touch ‘n Go eWallet dan klik 'Tambah Nilai Sekarang'.",
      "Successfully redeem Touch 'n Go eWallet voucher":
        "Tahniah! Anda telah berjaya menebus PIN Tambah Nilai Touch ‘n Go eWallet' anda.",
      HowToRedeem_GRAB_title: "Bagaimana untuk Menebus GrabGift Anda?",
      "Open the Grab app and choose the “Gifts” Tab.":
        "Buka aplikasi Grab dan pilih Tab 'Hadiah'.",
      "Click the Gifts icon at the upper right corner":
        "Klik pada ikon Hadiah di penjuru kanan sebelah atas.​",
      "Key-in your Gift Code": "Masukkan Kod Hadiah anda.​",
      "Click Pick Your Gift, then proceed with saving the voucher":
        "​Klik 'Pilih Hadiah Anda' dan teruskan untuk menyimpan baucar.",
      "Choose the gift": "​Pilih hadiah yang diingini.",
      "Your saved voucher will be stored in “My Rewards”":
        "Baucar anda akan disimpan dalam 'Ganjaran Saya'.",
      TNG_TNC_I:
        "Hanya SATU (1) Ganjaran PIN Tambah Nilai boleh digunakan untuk setiap tambah nilai.",
      TNG_TNC_II:
        "Untuk menggunakan PIN Tambah Nilai, log masuk ke laman web mikro. Di bawah 'Ganjaran', klik pada butang 'Lihat Sekarang' untuk ganjaran anda. Kemudian, klik pada ikon salin di sebelah Kod Baucar ganjaran masing-masing dan ikuti langkah yang disediakan.",

      TNG_TNC_III:
        "Sebaik sahaja ganjaran PIN Tambah Nilai digunakan, tindakan tersebut tidak boleh diterbalikkan. Jumlah yang ditebus tidak boleh ditukar dan tidak boleh dikembalikan untuk kredit, tunai atau sebarang bentuk penggantian, sama ada sebahagian atau sepenuhnya.",
      TNG_TNC_IV:
        "TNG Digital tidak akan menghubungi pengguna untuk mendapatkan maklumat peribadi atau kelayakan akaun berkenaan Kempen ini.",
      TNG_TNC_V: "PIN Tambah Nilai akan tamat tempoh pada",
      "31 Mar 2024": "31 Mar 2024",
      GRAB_TNC_I:
        "Pengguna mestimasukkan kod promosi semasa pembayaran untuk layak mendapat diskaun.",
      GRAB_TNC_II:
        "Kod promo ini sah untuk (1) SATU pesanan daripada perkhidmatan di GrabFood, GrabRide, GrabExpress atau GrabMart.",
      GRAB_TNC_III: "Kod promo ini akan tamat tempoh pada ",
      "31 May 2024": "31 Mei 2024",
      GRAB_TNC_IV:
        "Promosi tidak sah dengan mana-mana baucar atau promosi lain.",
      GRAB_TNC_V:
        "Grab berhak untuk mengubah, melanjutkan atau menamatkan promosi, atau meminda terma dan syarat mengikut budi bicara mutlaknya pada bila-bila masa tanpa notis terlebih dahulu. Sekiranya terdapat sebarang pertikaian secara langsung atau tidak langsung yang timbul daripada promosi, keputusan Grab adalah muktamad.",
      GRAB_TNC_VI:
        "Terma dan syarat ini akan ditadbir oleh undang-undang Malaysia dan sebarang pertikaian yang timbul daripada atau berkaitan dengan promosi akan dirujuk kepada bidang kuasa eksklusif mahkamah Malaysia.",
      SPAY_TNC_I: "Pengguna mesti memberikan butiran seperti di bawah:​",
      SPAY_username: "Nama pengguna/ID SPayGlobal​",
      SPAY_fullName: "Nama penuh",
      SPAY_NRIC: "Nombor kad pengenalan",
      SPAY_phone: "Nombor telefon (berdaftar dengan SPayGlobal)",
      SPAY_TNC_II:
        "Ia akan terus dimasukkan ke dompet pengguna. Sila pastikan dompet anda tidak berada pada jumlah maksimum dompet RM3,000.",
      SPAY_TNC_III:
        "SPAY Global mempunyai keputusan tunggal dan mutlak sama ada untuk memberikan anda Perkhidmatan atau tidak, di sini.",
      SPAY_TNC_IV:
        "Sila berikan sekurang-kurangnya 7 hari bekerja untuk Sarawak Pay mendebitkan ganjaran ke dompet pengguna.",
      "Copy to Clipboard": "Disalin ke papan keratan​",
      Close: "Tutup",
      "Sounds Good!": "Baik!",
      Hello: "Hello",
      "RELOAD PIN": "PIN TAMBAH NILAI​",
      Receipt: "Resit",
      TotalAmount: "Jumlah Keseluruhan",
      ReceiptPlaceholder: "Jumlah(RM)",
      TNG880: "PIN Tambah Nilai Touch ‘n Go eWallet bernilai RM8.80.",
      TNG1680: "PIN Tambah Nilai Touch ‘n Go eWallet bernilai RM16.80.",
      TNG5: "PIN Tambah Nilai Touch ‘n Go eWallet bernilai RM5.",
      GRAB5: "GrabGifts bernilai RM5.",
      SPAY5: "E-Kredit SPay bernilai RM5.",
      UploadSuccess:
        "Resit anda telah berjaya dimuat naik dan akan disahkan dalam tempoh 3 hari bekerja.",
      promoterCode: "Kod promotor",
      PaymentDetails: "Butiran Pembayaran",
      SPAYdesc: "Sila berikan butiran SPay anda untuk menerima ganjaran",
      SPAYusername: "Nama Pengguna / ID SPayGlobal",
      SPAYfullName: "Nama Penuh (seperti dalam kad pengenalan)",
      SPAYic: "Nombor Kad Pengenalan",
      SPAYphone: "No. Tel. (berdaftar dengan SPayGlobal)",

      // FE: validation error messages
      "Invalid name format!": "Format nama tidak sah",
      "Invalid phone number format!": "Format nombor telefon tidak sah",
      "Invalid email format!": "Format emel tidak sah",
      "Invalid promoter code": "Kod promosi tidak sah",
      "Promoter id not found": "Kod promosi tidak sah",
      "Only numbers and alphabets are allowed.":
        "Hanya nombor dan abjad yang dibenarkan.",
      "Campaign not started yet.": "Kempen belum bermula lagi.",
      "Campaign has ended.": "Kempen telah tamat.",
      "Campaign not available for your channel. Will redirect to you campaign page.":
        "Kempen tidak tersedia untuk saluran anda. Akan dialihkan ke halaman kempen anda.",
      "You're not eligible for this campaign. you'll be redirected to your campaign page.":
        "Anda tidak layak untuk kempen ini. Anda akan diarahkan ke halaman kempen anda.",
      "You have reached the maximum total claims":
        "Anda telah mencapai jumlah tuntutan maksimum",
      "Missing required fields": "Medan yang diperlukan hilang",
      "Please select at least one product!":
        "Sila pilih sekurang-kurangnya satu produk",
      "Please select an option first": "Sila pilih pilihan dahulu",
      "An error occurred while processing data":
        "Ralat berlaku semasa pemprosesan data",
      "Could not find reward for this receipt amount. Make sure to submit the correct receipt amount.":
        "Tidak dapat mencari ganjaran untuk jumlah resit ini. Pastikan untuk mengemukakan jumlah resit yang betul.",
      "Please upload the receipt image": "Sila muat naik gambar resit",
      "No Receipt Submission Yet": "No Receipt Submission Yet",
      "No Rewards Yet": "No Rewards Yet",
      "User has filled up the payment information ":
        "Pengguna telah mengisi maklumat pembayaran",
      "Please fill up all the required fields":
        "Sila lengkapkan semua medan yang diperlukan",
      "Invalid IC format! Must be 12 digits":
        "Format IC tidak sah! Mesti 12 digit",
      "This field is required": "Medan ini diperlukan",
      "user missing registerSource channel":
        "Pengguna hilang saluran registerSource",
      "Submission Update": "Kemas kini Penghantaran",
      "Reject Update":
        "Usaha hebat! Anda telah mencapai had penghantaran bulanan anda, tetapi kami perasan beberapa penyesuaian diperlukan untuk satu atau lebih resit. Klik butang 'Hantar Semula' untuk menggantikan resit-resit ini.",
      "Resubmit Now": "Hantar Semula",
    },
  },
  CH: {
    translation: {
      "Log In": "登录",
      "Key in Phone Number to Login now!": "请输入手机号码以登录​",
      "Your Phone Number": "您的手机号码",
      "SEND OTP CODE": "获取验证码​",
      "Invalid phone number format! Starts with 60":
        "错误的电话号码格式! 请以60开头",
      "OTP Verification": "OTP验证码",
      "Please type verification code sent to":
        "请输入发送到以下电话号码的验证码",
      "Didnt't get OTP?": "没有收到验证码？",
      "Resend OTP": "重新发送验证码",
      Verify: "验证",
      Registration: "注册",
      FullName: "全名",
      MobileNumber: "手机号码",
      Email: "电子邮件",
      "I agree to Haleon's": "我同意Haleon的",
      TnC: "条款和条件",
      PrivacyPolicy: "隐私政策",
      "and the": "以及",
      backUP: "我同意Haleon的条款和条件以及隐私政策",
      statement:
        "我授权Haleon向特定的第三方方提供我的个人数据*, 作为Haleon奖励会员提供增值服务​",
      Register: "注册",
      Successful: "成功!",
      "Registration Successful": "注册成功",
      Done: "完成",
      Questionnaire: "在过去的6个月里, 您购买过任何Haleon产品吗?",
      Yes: "是",
      No: "否",
      Submit: "提交",
      // "Upload purchase receipt to\nreceive rewards!": "传购物收据以获取奖励！​",
      UploadReceipt: "上传收据",
      Rewards: "查看奖励",
      "Terms & Conditions": "条款和条件",
      EVoucher: "充值码​",
      CampaignPeriod: "活动期间",
      January: "1月",
      February: "2月",
      copy: "2024年1月1日至2月29日",
      "Min Purchase of": "最低消费金额",
      RedeemNow: "立即兑换",
      "Upload receipt photo": "上传收据照片​      ",
      "Supported image format": "*支持的图像格式：仅限 JPEG, JPG, PNG",
      notes: "备注: 您的收据将在提交后的3个工作日内进行验证",
      "Select your reward": "选择您的奖励",
      "Submit Receipt": "提交收据​",
      "Your receipt has been successfully uploaded and will be validated within 3 working days.":
        "您的收据已成功上传, 将在3个工作日内进行验证.",
      StayTuned: "敬请期待！",
      "Something exciting is on the way.": "令人兴奋的事情即将到来​",
      "Stay Tuned for upcoming exciting rewards": "请期待即将推出的奖励。​",
      HowToRedeem: "如何兑换?",
      "Redeem Now": "立即兑换​",
      "exp. March 31": "截至2024年3月31日",
      Back: "返回",
      SPAY_AfterRedeem: "E-积分将存入您的SPay账户,您将在3个工作日内收到。",
      "My Rewards": "我的奖励​",
      "My Submissions": "我的提交",
      Rejected: "已拒绝​",
      Approved: "已批准",
      Pending: "待处理​",
      DateSubmitted: "提交日期",
      ReceiptDate: "收据日期",
      ReceiptAmount: "收据总额",
      ReceiptNumber: "收据编号",
      Later: "稍后",
      UploadAgain: "重新上传",
      "Your submission have been rejected": "您的提交已被拒绝",
      "Kindly upload your receipt again": "请重新上传您的收据​",
      HowToRedeem_TNG_title: "如何兑换您的 Touch 'n Go eWallet 充值码？",
      "Open the Touch 'n Go eWallet Reload PIN App":
        "打开 Touch 'n Go电子钱包应用程序",
      "Click 'Reload'": "点击‘充值",
      "Click 'eWallet Balance'": "选择’电子钱包余额’",
      "Click on 'TNG eWallet Reload PIN below of the page":
        "点击 Touch 'n Go 电子钱包充值码",
      "Key in the TNG eWallet Reload PIN and click 'Reload Now'":
        "输入 Touch 'n Go 电子钱包充值码，然后点击“立即充值”",
      "Successfully redeem Touch 'n Go eWallet voucher":
        "您已成功兑换 Touch 'n Go 电子钱包充值码",
      HowToRedeem_GRAB_title: "如何兑换 GrabGift?",
      "Open the Grab app and choose the “Gifts” Tab.":
        "打开 Grab 应用程序并选择“礼品卡”选项.",
      "Click the Gifts icon at the upper right corner": "点击右上角的礼物图标.",
      "Key-in your Gift Code": "输入您的礼品代码​.",
      "Click Pick Your Gift, then proceed with saving the voucher":
        "点击“选择您的礼物并继续保存” 优惠券​.",
      "Choose the gift": "选择想要的礼物​.",
      "Your saved voucher will be stored in “My Rewards”":
        "您保存的凭证将保存在“我的奖励’.",
      TNG_TNC_I: "每次充值只能使用一 (1) 个充值码​​",
      TNG_TNC_II:
        "在“奖励”下，点击“立即查看”按钮查看您的奖励。然后，单击相应奖励的优惠券代码旁边的复制图标，并按照步骤进操作​​.",
      TNG_TNC_III:
        "充值码奖励一旦使用，就无法撤销。兑换金额不可兑换，也不可退还为积分、现金或任何形式的部分或全部替代品​.",
      TNG_TNC_IV:
        "TNG Digital 不会联系用户以获取有关此活动的个人信息或帐户凭据.",
      TNG_TNC_V: "充值码将于",
      "31 Mar 2024": "2024年3月31日到期",
      GRAB_TNC_I: "用户必须在结账时输入促销代码才能享受折扣。",
      GRAB_TNC_II:
        "此促销代码适用于 (1) GrabFood、GrabRide、 GrabExpress 或 GrabMart。",
      GRAB_TNC_III: "此促销代码将于",
      "31 May 2024": "2024 年 5 月31 日到期。 ",
      GRAB_TNC_IV: "促销活动不适用于任何其他促销活动。优惠券或促销活动​.",
      GRAB_TNC_V:
        "Grab 保留随时自行决定更改、延长或终止促销活动, 或修改条款和条件的权利,恕不另行通知。如有任何因促销直接或间接产生的争议, Grab 保留最终决定权。",
      GRAB_TNC_VI:
        "这些条款和条件应受马来西亚法律管辖，任何因促销引起的或与之相关的争议均应提交马来西亚法院的专属管辖。",
      SPAY_TNC_I: "用户必须提供以下详细信息:",
      SPAY_username: "SPayGlobal用户名/ID",
      SPAY_fullName: "全名​",
      SPAY_NRIC: "身份证号码​",
      SPAY_phone: "手机号码(在SPayGlobal注册)",
      SPAY_TNC_II:
        "奖励将直接转入用户的钱包, 请确保您的钱包金额未达到最大限额RM3,000.",
      SPAY_TNC_III: "S PAY Global有绝对的酌情权是否向您提供本服务。",
      SPAY_TNC_IV:
        "请至少等待3个工作日,以便Sarawak Pay直接从奖励中扣除用户的钱包。",
      "Copy to Clipboard": "已复制到剪贴板​​",
      Close: "关闭",
      "Sounds Good!": "好的!",
      Hello: "你好",
      "RELOAD PIN": "充值码",
      Receipt: "收据",
      TotalAmount: "总数",
      ReceiptPlaceholder: "总数(RM)",
      TNG880: "Touch 'n Go电子钱包充值码价值RM8.80.",
      TNG1680: "Touch 'n Go电子钱包充值码价值RM16.80.",
      TNG5: "Touch 'n Go电子钱包充值码价值RM5.",
      GRAB5: "GRAB 电子钱包充值码价值RM5.",
      SPAY5: "SPay电子钱包充值码价值RM5.",
      UploadSuccess: "您的收据已成功上传,将在3个工作日内进行验证.",
      promoterCode: "推广员代码",
      PaymentDetails: "支付详情",
      SPAYdesc: "请提供您的SPay详细信息以领取奖励",
      SPAYusername: "SPayGlobal用户名/ID",
      SPAYfullName: "身份证上的全名",
      SPAYic: "身份证号码",
      SPAYphone: "手机号码 (已在SPayGlobal注册)",

      // FE: validation error messages
      "Invalid name format!": "无效的姓名格式",
      "Invalid phone number format!": "无效的电话号码格式",
      "Invalid email format!": "无效的电子邮件格式",
      "Invalid promoter code": "无效的推广员码",
      "promoter id not found": "无效的推广员码",
      "Only numbers and alphabets are allowed.": "只允许数字和字母",
      "Campaign not started yet.": "活动尚未开始",
      "Campaign has ended.": "活动已结束.",
      "Campaign not available for your channel. Will redirect to you campaign page.":
        "此商店不支持此活动。将定位到您的活动页面.",
      "You're not eligible for this campaign. you'll be redirected to your campaign page.":
        "您不符合此活动的资格。将定位到您的活动页面.",
      "You have reached the maximum total claims": "您已达到最大兑换次数",
      "Missing required fields": "缺少必填字段",
      "Please select at least one product!": "请至少选择一种产品",
      "Please select an option first": "请先选择一个选项",
      "An error occurred while processing data": "在处理数据时发生错误",
      "Could not find reward for this receipt amount. Make sure to submit the correct receipt amount.":
        "找不到与此收据金额相对应的奖励。请确保提交正确的收据金额.",
      "Please upload the receipt image": "请上传收据图像",
      "No Receipt Submission Yet": "尚未提交收据",
      "No Rewards Yet": "尚无奖励",
      "User has filled up the payment information ": "用户已填写付款信",
      "Please fill up all the required fields": "请填写所有必填字段",
      "Invalid IC format! Must be 12 digits":
        "无效的身份证格式! 必须为12位数字",
      "This field is required": "此字段为必填项",
      "user missing registerSource channel": "用户缺少registerSource",
      "Submission Update": "提交更新",
      "Reject Update":
        "非常棒！您已达到每月提交上限，但我们注意到一个或多个收据需要进行一些调整。请点击“重新提交”按钮以替换这些收据.",
      "Resubmit Now": "重新提交",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng:
    localStorage.getItem("language") &&
    localStorage.getItem("language") === "ENG"
      ? "ENG"
      : localStorage.getItem("language") === "BM"
      ? "BM"
      : "CH",
  fallbackLng: "ENG",
  keySeparator: ".",
  returnObjects: true,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
