import axios from "axios";
import { toCamelCase } from "./helper";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN_URL,
  headers: {
    "x-company-id": process.env.REACT_APP_COMPANY_ID,
  },
});

axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("user-token");

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // console.error("error", error);
    const dummyTransaltion = {
      EN: {
        "BadRequestError.InvalidToken": "Invalid token",
        "BadRequestError.InvalidRequest.MissingRequiredFields":
          "Invalid request. missing required fields.",
        "BadRequestError.InvalidRequest.PhoneIsRequired":
          "Invalid request. phone is required.",
        "ForbiddenError.Forbidden": "Forbidden",
        "BadRequestError.InvalidTokenType": "Invalid token type",
        "BadRequestError.RetailerNotFound": "Retailer not found",
        "BadRequestError.RetailerNotFoundOrPhoneNumberNotSet":
          "Retailer not found or Phone Number not set",
        "BadRequestError.SaleRepNotFoundOrPhoneNumberNotSet":
          "Sale Rep not found or Phone Number not set",
        "InternalServerError.FailedToRequestNewToken":
          "Failed to request new token",
        "NotFoundError.UserNotFoundOrNotAssociatedWithThisCompany":
          "User not found or not associated with this company",
        "UnauthorizedError.InvalidPassword": "Invalid password",
        "UnauthorizedError.PasswordNotSet": "Password not set",
        "BadRequestError.InvalidRequest.EmailIsRequired":
          "Invalid request. email is required",
        "BadRequestError.InvalidRequest.CompanyIdIsRequired":
          "Invalid request. companyId is required",
        "BadRequestError.PreviousCampaignEntryIsRequiredForResubmission":
          "Previous campaign entry is required for resubmission.",
        "BadRequestError.UserNotFound": "User not found",
        "InternalServerError.InternalServerError": "Internal Server Error",
        "NotFoundError.FailToGetRetailerId": "Fail to get retailer id",
        "NotFoundError.FailToGetRetailerIdOrInvalidReferralLink":
          "Fail to get retailer id or invalid referral link",
        "NotFoundError.InvalidReferralLink": "Invalid referral link",
        "NotFoundError.UserNotFound": "User not found.",
        "BadDigestError.UserAlreadySubmittedTheQuestionnaire":
          "User already submitted the questionnaire",
        "BadRequestError.CampaignIDIsRequired": "Campaign ID is required",
        "BadRequestError.DSRIdIsInvalid!": "DSR Id is invalid!",
        "BadRequestError.InvalidCampaignId.": "Invalid campaign id.",
        "BadRequestError.InvalidChannel.": "Invalid channel.",
        "BadRequestError.InvalidCompanyId.": "Invalid company id.",
        "BadRequestError.InvalidRegisterSource.": "Invalid register source.",
        "BadRequestError.ProvidedCampaignTypeRequiredImage.":
          "Provided campaign type required image.",
        "BadRequestError.UserDoesn'tHaveADSRAcount":
          "User doesn't have a DSR account",
        "ConflictError.ErrorCreatingCampaignEntry.":
          "Error creating campaign entry.",
        "ConflictError.ErrorCreatingReceipt.": "Error creating Receipt.",
        "ConflictError.ErrorUpdatingPreviousCampaignEntry'sReceipt.":
          "Error updating previous campaign entry's receipt.",
        "ConflictError.ErrorUpdatingPreviousCampaignEntry.":
          "Error updating previous campaign entry.",
        "ConflictError.ErrorUpdatingPreviousSubEntryReceipts.":
          "Error updating previous sub entry receipts.",
        "ForbiddenError.YouAreNotAllowedToAccessThisCampaign.":
          "You are not allowed to access this campaign.",
        "InternalError.ErrorSavingFile.": "Error saving file.",
        "NotFoundError.CampaignNotFound'": "Campaign not found'",
        "NotFoundError.UserDidNotCompleteTheQuestionnaire":
          "User did not complete the questionnaire",
        "ConflictError.ErrorUpdatingOneOfPreviousCampaignEntry.":
          "Error updating one of previous campaign entry.",
        "BadRequestError.DSRIsInvalid!": "DSR is invalid!",
        "BadRequestError.InvalidCompany": "Invalid company",
        // "BadRequestError.InvalidToken": "Invalid token",
        "BadRequestError.InvalidTokenAction": "Invalid token action",
        "BadRequestError.MissingRequiredId.": "Missing required id.",
        "BadRequestError.OrderIDIsRequired": "Order ID is required",
        "BadRequestError.OrderIsNotShippedYet": "Order is not shipped yet",
        "BadRequestError.OrderItemNotFound": "Order item not found",
        "BadRequestError.ProvidedRequiredChannel,StoreNameAndCompanyId.":
          "Provided required channel, storeName and companyId.",
        "BadRequestError.ProvidedRequiredImageAndType.":
          "Provided required image and type.",
        "BadRequestError.QueryIsEmpty!": "Query is empty!",
        "BadRequestError.TokenHasExpired": "Token has expired",
        "BadRequestError.YouHaveReviewedThisItem":
          "You have reviewed this item",
        "InternalServerError.FailedToGenerateVerificationToken":
          "Failed to generate verification token",
        "NotFoundError.PleaseFillInTheRequiredDetails":
          "Please fill in the required details",
        "NotFoundError.UserDoesNotHaveAccessPrivileges":
          "User does not have access privileges",
        "NotFoundError.UserNotFoundOrNoLongerPartOfTheOrganization":
          "User not found or no longer part of the organization",
        "BadRequestError.MissingRequiredFields": "Missing required fields",
        "BadRequestError.UserHasBeenDeactivated": "User has been deactivated",
        "InternalServerError.FailedToSendSMS": "Failed to send SMS",
        "NotFoundError.EmailConfigNotFound": "Email config not found",
        "BadDigestError.FailedToDeleteAddress": "Failed to delete address",
        "BadDigestError.FailedToUpdateAddress": "Failed to update address",
        "BadDigestError.FailedToUpdatePersonalInfo":
          "failed to update personal info",
        "BadRequestError.UserOnlyAllowedToHaveMaximumOf5Addresses":
          "User only allowed to have maximum of 5 addresses",
        "NotFoundError.AddressNotFound": "Address Not Found",
        "NotFoundError.ArticleNotFoundOrNotAssociatedWithThisCompany":
          "Article not found or not associated with this company",
        "NotFoundError.CampaignIDNotFound": "Campaign ID not found",
        "NotFoundError.NoArticlesExistInThisCompanyId":
          "No articles exist in this company id",
        "NotFoundError.NoPointHistoryFound": "No point history found",
        "NotFoundError.UserHasNotSubmittedAnyCampaignEntry":
          "User has not submitted any campaign entry",
        "NotFoundError.UserIsNotAssociatedWithAnyCompanyId":
          "User is not associated with any company id",
        "NotFoundError.UserPersonalInfoNotFound":
          "User personal info not found",
        "BadDigestError.FailedToCreateDeliveryAddress":
          "Failed to create delivery address",
        "BadDigestError.FailedToUpdateTheRedemptionStatus":
          "Failed to update the redemption status",
        "BadDigestError.UserAlreadyHas5AddressesInTotal!":
          "User already has 5 addresses in total!",
        "BadDigestError.VoucherClickedMoreThanOnce!":
          "Voucher clicked more than once!",
        "NotFoundError.AddressNotFoundOrNotAssociatedWithTheUser":
          "Address Not Found or not associated with the user",
        "NotFoundError.PleaseProvideVoucherInfo": "Please provide voucher info",
        "NotFoundError.VoucherDoesNotExistInTheCompanyRewardSystem":
          "Voucher does not exist in the company reward system",
        "NotFoundError.VoucherNotFound": "Voucher not found",
        "NotFoundError.VoucherRedemptionHistoryNotFound":
          "Voucher redemption history not found",
        "NotFoundError.AddressNotCreated": "Address not created",
        "BadDigestError.FailToUpdatePaymentInformation":
          "Fail to update payment information",
        "BadDigestError.UserAlreadySubmittedThisInfo":
          "User already submitted this info",
        "NotFoundError.NoCampaignInfoFoundYet": "No campaign info found yet",
        "NotFoundError.NoPaymentDetailsFound": "No payment details found",
        "NotFoundError.QuestionnaireAssociatedWithThisCampaignIDNotFound":
          "Questionnaire associated with this campaign ID not found",
        "NotFoundError.VoucherAssociatedWithReceiptSubmissionNotFound!":
          "Voucher associated with receipt submission not found!",
        "NotFoundError.VoucherNotFound!": "voucher not found!",
        "InternalError.ErrorImportingData": "Error importing data:', error",
        "BadRequestError.AddressNotFound!": "Address not found!",
        "BadRequestError.CampaignEntryNotFound!": "Campaign Entry not found!",
        "BadRequestError.CampaignNotFound!": "Campaign not found!",
        "BadRequestError.EmailAlreadyExists": "Email already exists",
        "BadRequestError.InvalidRequest.MissingRequiredFields.":
          "Invalid request. missing required fields.",
        "BadRequestError.InvalidRole": "Invalid role",
        "BadRequestError.InvalidSelectedRewardIds.":
          "Invalid selected reward ids.",
        "BadRequestError.StaffCodeAlreadyExists": "Staff code already exists",
        "BadRequestError.UserAlreadyExists": "user already exists",
        "ConflictError.CampaginIsNotActive.": "Campagin is not active.",
        "ConflictError.ErrorCreatingCampaginEntry.":
          "Error creating Campagin entry.",
        "InternalServerError.FailedToCreateAppUser":
          "Failed to create app user",
        "InternalServerError.FailedToCreateUser": "Failed to create user",
        "BadRequestError.AlreadyExpired!": "Already expired!",
        "BadRequestError.CompanyIdIsInvalid!": "companyId is invalid!",
        "BadRequestError.IdIsInvalid!": "id is invalid!",
        "BadRequestError.Inactive!": "Inactive!",
        "BadRequestError.InsufficientPoints.": "Insufficient Points.",
        "BadRequestError.MissingEmailConfig": "Missing email config",
        "BadRequestError.NotFound!": "Not found!",
        "BadRequestError.OutOfStock!": "Out of stock!",
        "BadRequestError.ShopItemNotFound!": "ShopItem not found!",
        "ConflictError.ErrorOnCreatingDeductPoint.":
          "Error on creating deduct point.",
        "ConflictError.ErrorOnDeductingPointFromUser.":
          "Error on deducting point from user.",
        "ConflictError.ErrorUploadingFile.": "Error uploading file.",
        "InternalError.ErrorInUploadFile": "Error in upload File",
        "InternalServerError.FailedToSendEmail": "Failed to send email",
        "UnprocessableEntityError.MissingRequiredFields":
          "Missing required fields",
        "BadRequestError.InsufficientStock.": "Insufficient Stock.",
        "BadDigestError.CampaignEntryNotFound": "campaign entry not found",
        "BadRequestError.AddressIsRequired": "Address is required",
        "BadRequestError.CompanyUserIdIsInvalid!": "CompanyUser Id is invalid!",
        "BadRequestError.FormatNotSupported": "Format not supported",
        "BadRequestError.OrderIdIsInvalid!": "Order Id is invalid!",
        "BadRequestError.OrderNotFound!": "Order not found!",
        "BadRequestError.RetailerNotFound!": "Retailer not found!",
        "BadRequestError.StoreAlreadyExist": "Store already exist",
        "BadRequestError.StoreCodeAlreadyExist": "Store Code already exist",
        "BadRequestError.TotalPointsDoesNotMatchWithTheCartSummary":
          "Total points does not match with the cart summary",
        "BadRequestError.UserIdIsInvalid!": "User Id is invalid!",
        "ConflictError.AddressNotFound!": "Address not found!",
        "ConflictError.ErrorDeductingWalletPoints.":
          "Error deducting wallet points.",
        "ConflictError.ErrorOnCreatingOrder.": "Error on creating order.",
        "ConflictError.ErrorOnCreatingOrderItem.":
          "Error on creating OrderItem.",
        "ConflictError.ErrorOnUpdatingReward.": "Error on updating reward.",
        "ConflictError.InsufficientStock.": "Insufficient Stock.",
        "ConflictError.ShopItem'sRewardNotFound.":
          "Shop item's reward not found.",
        "ConflictError.ShopItemIsMissing": "Shop item is missing",
        "FailedDependencyError.UnableToGetRewardStocks.":
          "Unable to get reward stocks.",
        "BadRequestError.DSRNotFound!": "DSR not found!",
        "BadRequestError.CartIdIsInvalid!": "Cart Id is invalid!",
        "BadRequestError.CartNotFound!": "Cart not found!",
        "BadRequestError.CompanyNotFound!": "Company not found!",
        "BadRequestError.CompanyUserNotFound!": "Company User not found!",
        "BadRequestError.Issue/sOnSelectedItems.": "Issue/s on selected items.",
        "BadRequestError.ProvidedItemTypeCan'tBePurchase.":
          "Provided item type can't be purchase.",
        "BadRequestError.SelectedReward(s)IsNotPartOfTheCampaign":
          "Selected reward(s) is not part of the campaign",
        "BadRequestError.UserNotFound!": "User not found!",
        "BadRequestError.CampaignRewardIsOutOfStock":
          "Campaign Reward is out of stock",
        "BadRequestError.PreviousCampaignEntryIsNotCancelled.":
          "Previous campaign entry is not cancelled.",
        "BadRequestError.SelectedRewardIdsMustBeAnArray":
          "selectedRewardIds must be an array",
        "BadRequestError.You'veAlreadyResubmittedForThisEntry.":
          "You've already resubmitted for this entry.",
        "ForbiddenError.YouAreNotAllowedToResubmitThisEntry.":
          "You are not allowed to resubmit this entry.",
        "BadRequestError.PreviousCampaignEntryIdIsRequired.":
          "Previous campaign entry id is required.",
        "BadDigestError.DuplicateAddressFound": "Duplicate address found",
        "BadDigestError.VoucherAlreadyExpired": "Voucher already expired",
        "BadRequestError.InvalidRequest.TokenTypeIsRequired.":
          "Invalid request. Token type is required.",
        "BadRequestError.ThisSelectedRewardIsNotPartOfTheCampaign":
          "This selected reward is not part of the campaign",
        "BadRequestError.YouHaveReachedTheMaximumNumberOfEntries":
          "You have reached the maximum number of entries",
        "BadRequestError.InvalidRequest.RecipientFieldIsRequired.":
          "Invalid request. Recipient field is required.",
      },
      BM: {
        "BadRequestError.InvalidToken":
          "Ralat Permintaan Tidak Baik: Token tidak sah",
        "BadRequestError.InvalidRequest.MissingRequiredFields":
          "Ruangan yang diperlukan tidak lengkap",
        "BadRequestError.InvalidRequest.PhoneIsRequired":
          "Ralat Permintaan Tidak Baik: Nombor telefon tidak sah diperlukan",
        "ForbiddenError.Forbidden": "Dilarang",
        "BadRequestError.InvalidTokenType":
          "Ralat Permintaan Tidak Baik: Jenis token tidak sah",
        "BadRequestError.RetailerNotFound": "Peruncit tidak dijumpai",
        "BadRequestError.RetailerNotFoundOrPhoneNumberNotSet":
          "Ralat Permintaan Tidak Baik: Pengecer tidak dijumpai atau Nombor Telefon tidak ditetapkan",
        "BadRequestError.SaleRepNotFoundOrPhoneNumberNotSet":
          "Wakil Jualan tidak dijumpai atau Nombor Telefon tidak ditetapkan",
        "InternalServerError.FailedToRequestNewToken":
          "Ralat Dalaman: Gagal meminta token baru",
        "NotFoundError.UserNotFoundOrNotAssociatedWithThisCompany":
          "pengguna tidak dijumpai",
        "UnauthorizedError.InvalidPassword": "Kata laluan tidak sah",
        "UnauthorizedError.PasswordNotSet":
          "Ralat Tidak Dibenarkan: Kata laluan tidak ditetapkan",
        "BadRequestError.InvalidRequest.EmailIsRequired":
          "Ralat Permintaan Tidak Baik: E-mel tidak sah diperlukan",
        "BadRequestError.InvalidRequest.CompanyIdIsRequired":
          "Ralat Permintaan Tidak Baik: ID Syarikat tidak sah diperlukan",
        "BadRequestError.PreviousCampaignEntryIsRequiredForResubmission":
          "Entri kempen sebelum ini diperlukan untuk penghantaran semula.",
        "BadRequestError.UserNotFound": "pengguna tidak dijumpai",
        "InternalServerError.InternalServerError":
          "Ralat Dalaman: Ralat Server Dalaman",
        "NotFoundError.FailToGetRetailerId":
          "Ralat Tidak Dijumpai: Gagal mendapatkan ID pengecer",
        "NotFoundError.FailToGetRetailerIdOrInvalidReferralLink":
          "Ralat Tidak Dijumpai: Gagal mendapatkan ID pengecer atau pautan rujukan tidak sah",
        "NotFoundError.InvalidReferralLink": "Invalid referral link",
        "NotFoundError.UserNotFound": "pengguna tidak dijumpai",
        "BadDigestError.UserAlreadySubmittedTheQuestionnaire":
          "Pengguna sudah mengemukakan soal selidik",
        "BadRequestError.CampaignIDIsRequired": "Campaign ID is required",
        "BadRequestError.DSRIdIsInvalid!":
          "Ralat Permintaan Tidak Baik: DSR tidak sah!",
        "BadRequestError.InvalidCampaignId.": "Invalid campaign id.",
        "BadRequestError.InvalidChannel.": "Invalid channel.",
        "BadRequestError.InvalidCompanyId.":
          "Ralat Permintaan Tidak Baik: Syarikat ID tidak sah",
        "BadRequestError.InvalidRegisterSource.":
          "Ralat Permintaan Tidak Baik: Sumber pendaftaran tidak sah.",
        "BadRequestError.ProvidedCampaignTypeRequiredImage.":
          "Ralat Permintaan Tidak Baik: Jenis kempen yang diberikan memerlukan imej.",
        "BadRequestError.UserDoesn'tHaveADSRAcount": "Pengguna tiada akaun DSR",
        "ConflictError.ErrorCreatingCampaignEntry.":
          "Ralat Bentrok: Ralat mencipta kemasukan kempen.",
        "ConflictError.ErrorCreatingReceipt.":
          "Ralat Bentrok: Ralat mencipta Resit.",
        "BadDigestError.UserAlreadySubmittedThisInfo":
          "Pengguna telah menghantar maklumat ini",
        "ConflictError.ErrorUpdatingPreviousCampaignEntry'sReceipt.":
          "Ralat Bentrok: Ralat mengemas kini Resit kemasukan kempen sebelum ini.",
        "ConflictError.ErrorUpdatingPreviousCampaignEntry.":
          "Ralat Bentrok: Ralat mengemas kini kemasukan kempen sebelum ini.",
        "ConflictError.ErrorUpdatingPreviousSubEntryReceipts.":
          "Ralat Bentrok: Ralat mengemas kini resit sub kemasukan kempen sebelum ini.",
        "ForbiddenError.YouAreNotAllowedToAccessThisCampaign.":
          "Ralat Terlarang: Anda tidak dibenarkan mengakses kempen ini.",
        "InternalError.ErrorSavingFile.":
          "Ralat Dalaman: Gagal menyimpan fail.",
        "NotFoundError.CampaignNotFound'":
          "Ralat Tidak Dijumpai: Kempen tidak dijumpai",
        "NotFoundError.UserDidNotCompleteTheQuestionnaire":
          "Pengguna tidak lengkapkan soal selidik",
        "ConflictError.ErrorUpdatingOneOfPreviousCampaignEntry.":
          "Ralat Bentrok: Ralat mengemas kini satu daripada kemasukan kempen sebelum ini.",
        "BadRequestError.DSRIsInvalid!": "DST tidak sah!!",
        "BadRequestError.InvalidCompany":
          "Ralat Permintaan Tidak Baik: Syarikat tidak sah",
        "BadRequestError.InvalidTokenAction":
          "Ralat Permintaan Tidak Baik: Tindakan token tidak sah",
        "BadRequestError.MissingRequiredId.":
          "Ralat Permintaan Tidak Baik: ID yang diperlukan hilang.",
        "BadRequestError.OrderIDIsRequired":
          "Ralat Permintaan Tidak Baik: ID Pesanan diperlukan",
        "BadRequestError.OrderIsNotShippedYet":
          "Ralat Permintaan Tidak Baik: Pesanan belum dihantar lagi",
        "BadRequestError.OrderItemNotFound": "Item pesanan tidak dijumpai",
        "BadRequestError.ProvidedRequiredChannel,StoreNameAndCompanyId.":
          "Ralat Permintaan Tidak Baik: Saluran, Nama Kedai, dan ID Syarikat yang Diperlukan tidak disediakan.",
        "BadRequestError.ProvidedRequiredImageAndType.":
          "Ralat Permintaan Tidak Baik: Gambar dan Jenis yang Diperlukan tidak disediakan.",
        "BadRequestError.QueryIsEmpty!":
          "Ralat Permintaan Tidak Baik: Pertanyaan adalah kosong!",
        "BadRequestError.TokenHasExpired":
          "Ralat Permintaan Tidak Baik: Token telah tamat tempoh",
        "BadRequestError.YouHaveReviewedThisItem":
          "Anda telah menyemak item ini",
        "InternalServerError.FailedToGenerateVerificationToken":
          "Ralat Dalaman: Gagal menghasilkan token pengesahan",
        "NotFoundError.PleaseFillInTheRequiredDetails":
          "Sila lengkapkan butiran yang diperlukan",
        "NotFoundError.UserDoesNotHaveAccessPrivileges":
          "Ralat Tidak Dijumpai: Pengguna tidak mempunyai keistimewaan capaian",
        "NotFoundError.UserNotFoundOrNoLongerPartOfTheOrganization":
          "pengguna tidak dijumpai",
        "BadRequestError.MissingRequiredFields":
          "Ruangan yang diperlukan tidak lengkap",
        "BadRequestError.UserHasBeenDeactivated": "User has been deactivated",
        "InternalServerError.FailedToSendSMS": "Gagal menghantar SMS",
        "NotFoundError.EmailConfigNotFound": "Konfigurasi e-mel tidak dijumpai",
        "BadDigestError.FailedToDeleteAddress": "Gagal memadam alamat",
        "BadDigestError.FailedToUpdateAddress": "Gagal mengemaskini alamat",
        "BadDigestError.FailedToUpdatePersonalInfo":
          "Gagal mengemaskini maklumat peribadi",
        "BadRequestError.UserOnlyAllowedToHaveMaximumOf5Addresses":
          "Pengguna hanya dibenarkan mempunyai maksimum 5 alamat",
        "NotFoundError.AddressNotFound":
          "Ralat Tidak Dijumpai: Alamat Tidak Ditemui",
        "NotFoundError.ArticleNotFoundOrNotAssociatedWithThisCompany":
          "Ralat Tidak Dijumpai: Artikel tidak dijumpai atau tidak berkaitan dengan syarikat ini",
        "NotFoundError.CampaignIDNotFound":
          "Ralat Tidak Dijumpai: ID Kempen tidak dijumpai",
        "NotFoundError.NoArticlesExistInThisCompanyId":
          "Ralat Tidak Dijumpai: Tiada artikel wujud dalam ID syarikat ini",
        "NotFoundError.NoPointHistoryFound":
          "Ralat Tidak Dijumpai: Tiada sejarah mata poin dijumpai",
        "NotFoundError.UserHasNotSubmittedAnyCampaignEntry":
          "Ralat Tidak Dijumpai: Pengguna belum mengemukakan sebarang kemasukan kempen",
        "NotFoundError.UserIsNotAssociatedWithAnyCompanyId":
          "Ralat Tidak Dijumpai: Pengguna tidak berkaitan dengan sebarang ID syarikat",
        "NotFoundError.UserPersonalInfoNotFound":
          "Maklumat peribadi pengguna tidak dijumpai",
        "BadDigestError.FailedToCreateDeliveryAddress":
          "Ralat Penghadaman: Gagal untuk mencipta alamat penghantaran",
        "BadDigestError.FailedToUpdateTheRedemptionStatus":
          "Gagal mengemaskini status penebusan",
        "BadDigestError.UserAlreadyHas5AddressesInTotal!":
          "Pengguna sudah mempunyai 5 alamat secara keseluruhan!",
        "BadDigestError.VoucherClickedMoreThanOnce!":
          "Ralat Penghadaman: Voucher diklik lebih dari sekali!",
        "NotFoundError.AddressNotFoundOrNotAssociatedWithTheUser":
          "Alamat tidak ditemui",
        "NotFoundError.PleaseProvideVoucherInfo":
          "Sila berikan maklumat baucar",
        "NotFoundError.VoucherDoesNotExistInTheCompanyRewardSystem":
          "Voucher tidak wujud dalam sistem ganjaran syarikat",
        "NotFoundError.VoucherNotFound": "Baucar tidak ditemui",
        "NotFoundError.VoucherRedemptionHistoryNotFound":
          "Sejarah penebusan voucher tidak ditemui",
        "NotFoundError.AddressNotCreated": "Alamat tidak dicipta",
        "BadDigestError.FailToUpdatePaymentInformation":
          "Gagal mengemaskini maklumat pembayaran",
        "BadDigestError.UserHasFilledUpThePaymentInformation":
          "Pengguna telah mengisi maklumat pembayaran",
        "NotFoundError.NoCampaignInfoFoundYet": "Tiada maklumat kempen ditemui",
        "NotFoundError.NoPaymentDetailsFound":
          "Tiada butiran pembayaran ditemui",
        "NotFoundError.QuestionnaireAssociatedWithThisCampaignIDNotFound":
          "Soal selidik yang berkaitan dengan ID kempen ini tidak ditemui",
        "NotFoundError.VoucherAssociatedWithReceiptSubmissionNotFound!":
          "Voucher yang berkaitan dengan penghantaran resit tidak ditemui!",
        "NotFoundError.VoucherNotFound!": " Baucar tidak ditemui!",
        "InternalError.ErrorImportingData": "Ralat mengimport data: ',ralat",
        "BadRequestError.AddressNotFound!": "Alamat tidak dijumpai!",
        "BadRequestError.CampaignEntryNotFound!": "Entri kempen tidak ditemui!",
        "BadRequestError.CampaignNotFound!": "Kempen tidak ditemui!",
        "BadRequestError.EmailAlreadyExists": "E-mel telah wujud",
        "BadRequestError.InvalidRequest.MissingRequiredFields.":
          "Ruangan yang diperlukan tidak lengkap.",
        "BadRequestError.InvalidRole": "Peranan tidak sah",
        "BadRequestError.InvalidSelectedRewardIds.":
          "ID ganjaran yang dipilih tidak sah",
        "BadRequestError.StaffCodeAlreadyExists": "Kod staf telah wujud",
        "BadRequestError.UserAlreadyExists": "Pengguna telah wujud",
        "ConflictError.CampaginIsNotActive.": "Kempen tidak aktif",
        "ConflictError.ErrorCreatingCampaginEntry.":
          "Ralat mencipta entri kempen",
        "InternalServerError.FailedToCreateAppUser":
          "Failed to create app user",
        "InternalServerError.FailedToCreateUser": "Gagal mencipta pengguna",
        "BadRequestError.AlreadyExpired!": "Sudah tamat tempoh!",
        "BadRequestError.CompanyIdIsInvalid!": "ID syarikat tidak sah!",
        "BadRequestError.IdIsInvalid!": "ID tidak sah!",
        "BadRequestError.Inactive!": "Tidak aktif!",
        "BadRequestError.InsufficientPoints.": "Mata ganjaran tidak mencukupi",
        "BadRequestError.MissingEmailConfig": "Konfigurasi e-mel hilang",
        "BadRequestError.NotFound!": "Tidak ditemui!",
        "BadRequestError.OutOfStock!": "Stok habis",
        "BadRequestError.ShopItemNotFound!": "Item kedai tidak ditemui!",
        "ConflictError.ErrorOnCreatingDeductPoint.":
          "Ralat dalam mencipta mata tolak",
        "ConflictError.ErrorOnDeductingPointFromUser.":
          "Ralat dalam mengurangkan mata dari pengguna.",
        "ConflictError.ErrorUploadingFile.": "Ralat dalam memuat naik fail",
        "InternalError.ErrorInUploadFile": "Ralat dalam memuat naik fail",
        "InternalServerError.FailedToSendEmail": "Gagal menghantar e-mel",
        "UnprocessableEntityError.MissingRequiredFields":
          "Ruangan yang diperlukan tidak lengkap",
        "BadRequestError.InsufficientStock.": "Stok tidak mencukupi",
        "BadDigestError.CampaignEntryNotFound": "Entri kempen tidak ditemui",
        "BadRequestError.AddressIsRequired": "Alamat diperlukan",
        "BadRequestError.CompanyUserIdIsInvalid!":
          "ID Pengguna Syarikat tidak sah!",
        "BadRequestError.FormatNotSupported": "Format tidak disokong",
        "BadRequestError.OrderIdIsInvalid!": "ID Pesanan tidak sah!",
        "BadRequestError.OrderNotFound!": "Pesanan tidak ditemui!",
        "BadRequestError.RetailerNotFound!": "Peruncit tidak dijumpai!",
        "BadRequestError.StoreAlreadyExist": "Kedai telah wujud",
        "BadRequestError.StoreCodeAlreadyExist": "Kod kedai telah wujud",
        "BadRequestError.TotalPointsDoesNotMatchWithTheCartSummary":
          "Jumlah mata tidak sepadan dengan ringkasan troli",
        "BadRequestError.UserIdIsInvalid!": "ID Pengguna tidak sah!",
        "ConflictError.AddressNotFound!": "Alamat tidak dijumpai!",
        "ConflictError.ErrorDeductingWalletPoints.":
          "Error deducting wallet points",
        "ConflictError.ErrorOnCreatingOrder.": "Ralat dalam mencipta pesanan",
        "ConflictError.ErrorOnCreatingOrderItem.":
          "Ralat dalam mencipta Item Pesanan",
        "ConflictError.ErrorOnUpdatingReward.":
          "Ralat dalam mengemaskini ganjaran",
        "ConflictError.InsufficientStock.": "Stok tidak mencukupi",
        "ConflictError.ShopItem'sRewardNotFound.":
          "Ganjaran item kedai tidak ditemui",
        "ConflictError.ShopItemIsMissing": "Item kedai hilang",
        "FailedDependencyError.UnableToGetRewardStocks.":
          "Tidak dapat mendapatkan stok ganjaran.",
        "BadRequestError.DSRNotFound!": "DSR tidak ditemui!",
        "BadRequestError.CartIdIsInvalid!": "ID cart tidak sah!",
        "BadRequestError.CartNotFound!": "Cart tidak ditemui!",
        "BadRequestError.CompanyNotFound!": "tidak ditemui!",
        "BadRequestError.CompanyUserNotFound!": "Pengguna tidak ditemui!",
        "BadRequestError.Issue/sOnSelectedItems.":
          "Isu/isu pada item yang dipilih",
        "BadRequestError.ProvidedItemTypeCan'tBePurchase.":
          "Jenis item yang disediakan tidak boleh dibeli",
        "BadRequestError.SelectedReward(s)IsNotPartOfTheCampaign":
          "Ganjaran yang dipilih tidak merupakan sebahagian daripada kempen",
        "BadRequestError.UserNotFound!": "pengguna tidak dijumpai!",
        "BadRequestError.CampaignRewardIsOutOfStock":
          "Ganjaran Kempen habis stok",
        "BadRequestError.PreviousCampaignEntryIsNotCancelled.":
          "Entri kempen sebelumnya tidak dibatalkan.",
        "BadRequestError.SelectedRewardIdsMustBeAnArray":
          "ID Ganjaran yang Dipilih mesti menjadi array",
        "BadRequestError.You'veAlreadyResubmittedForThisEntry.":
          "Anda telah mengemukakan semula untuk entri ini.",
        "ForbiddenError.YouAreNotAllowedToResubmitThisEntry.":
          "Anda tidak dibenarkan mengemukakan semula entri ini",
        "BadRequestError.PreviousCampaignEntryIdIsRequired.":
          "ID entri kempen sebelumnya diperlukan",
        "BadDigestError.DuplicateAddressFound": "Alamat pendua ditemui",
        "BadDigestError.VoucherAlreadyExpired": "Baucar telah tamat tempoh",
        "BadRequestError.InvalidRequest.TokenTypeIsRequired.":
          "Permintaan tidak sah. Jenis token diperlukan.",
        "BadRequestError.ThisSelectedRewardIsNotPartOfTheCampaign":
          "Ganjaran yang dipilih ini bukan sebahagian daripada kempen",
        "BadRequestError.YouHaveReachedTheMaximumNumberOfEntries":
          "Anda telah mencapai jumlah entri maksimum",
        "BadRequestError.InvalidRequest.RecipientFieldIsRequired.":
          "Permintaan tidak sah. Ruang penerima diperlukan.",
      },
      CH: {
        "BadRequestError.InvalidToken": "无效托克",
        "BadRequestError.InvalidRequest.MissingRequiredFields":
          "无效请求。需要电话号码",
        "BadRequestError.InvalidRequest.PhoneIsRequired": "请输入您的电话号码",
        "ForbiddenError.Forbidden": "禁止访问",
        "BadRequestError.InvalidTokenType": "未找到令牌",
        "BadRequestError.RetailerNotFound": "未找到零售商",
        "BadRequestError.RetailerNotFoundOrPhoneNumberNotSet":
          "未找到零售商或未设置电话号码",
        "BadRequestError.SaleRepNotFoundOrPhoneNumberNotSet":
          "未找到销售代表或未设置电话号码",
        "InternalServerError.FailedToRequestNewToken": "无法请求新令牌",
        "NotFoundError.UserNotFoundOrNotAssociatedWithThisCompany":
          "用户未找到或未与此公司关联",
        "UnauthorizedError.InvalidPassword": "无效密码",
        "UnauthorizedError.PasswordNotSet": "密码未设置",
        "BadRequestError.InvalidRequest.EmailIsRequired":
          "无效请求。需要电子邮件",
        "BadRequestError.InvalidRequest.CompanyIdIsRequired":
          "无效请求。需要公司ID",
        "BadRequestError.PreviousCampaignEntryIsRequiredForResubmission":
          "重新提交需要先前的活动记录.",
        "BadRequestError.UserNotFound": "用户未找到",
        "InternalServerError.InternalServerError": "内部服务器错误",
        "NotFoundError.FailToGetRetailerId": "F无法获取零售商ID",
        "NotFoundError.FailToGetRetailerIdOrInvalidReferralLink":
          "无法获取零售商ID或推荐链接",
        "NotFoundError.InvalidReferralLink": "无法获取推荐链接",
        "NotFoundError.UserNotFound": "用户未找到",
        "BadDigestError.UserAlreadySubmittedTheQuestionnaire":
          "用户已提交问卷调查",
        "BadRequestError.CampaignIDIsRequired": "需要活动ID",
        "BadRequestError.DSRIdIsInvalid!": "DSR ID无效!",
        "BadRequestError.InvalidCampaignId.": "无效的活动ID",
        "BadRequestError.InvalidChannel.": "无效的频道",
        "BadRequestError.InvalidCompanyId.": "无效的公司ID",
        "BadRequestError.InvalidRegisterSource.": "无效的注册来源",
        "BadRequestError.ProvidedCampaignTypeRequiredImage.":
          "提供的活动类型需要图像",
        "BadRequestError.UserDoesn'tHaveADSRAcount": "用户没有DSR账户",
        "ConflictError.ErrorCreatingCampaignEntry.": "创建活动条目时出错",
        "ConflictError.ErrorCreatingReceipt.": "创建收据时出错",
        "ConflictError.ErrorUpdatingPreviousCampaignEntry'sReceipt.":
          "更新先前活动条目的收据时出错",
        "ConflictError.ErrorUpdatingPreviousCampaignEntry.":
          "更新先前活动条目时出错",
        "ConflictError.ErrorUpdatingPreviousSubEntryReceipts.":
          "更新先前子条目的收据时出错.",
        "ForbiddenError.YouAreNotAllowedToAccessThisCampaign.":
          "您无权访问此活动",
        "InternalError.ErrorSavingFile.": "保存文件时出错",
        "NotFoundError.CampaignNotFound'": "未找到活动'",
        "NotFoundError.UserDidNotCompleteTheQuestionnaire":
          "用户未完成问卷调查",
        "BadDigestError.UserAlreadySubmittedThisInfo":
          "用户已经提交了这些信息",
        "ConflictError.ErrorUpdatingOneOfPreviousCampaignEntry.":
          "Error updating one of previous campaign entry.",
        "BadRequestError.DSRIsInvalid!": "DSR无效!",
        "BadRequestError.InvalidCompany": "无效的公司",
        "BadRequestError.InvalidTokenAction": "无效的令牌操作",
        "BadRequestError.MissingRequiredId.": "缺少必要的ID。",
        "BadRequestError.OrderIDIsRequired": "需要订单ID",
        "BadRequestError.OrderIsNotShippedYet": "订单尚未发货",
        "BadRequestError.OrderItemNotFound": "未找到订单项",
        "BadRequestError.ProvidedRequiredChannel,StoreNameAndCompanyId.":
          "提供所需的频道,店名和公司ID。",
        "BadRequestError.ProvidedRequiredImageAndType.":
          "提供所需的图像和类型。",
        "BadRequestError.QueryIsEmpty!": "查询为空！",
        "BadRequestError.TokenHasExpired": "令牌已过期",
        "BadRequestError.YouHaveReviewedThisItem": "您已查看此项",
        "InternalServerError.FailedToGenerateVerificationToken":
          "生成验证令牌时出错",
        "NotFoundError.PleaseFillInTheRequiredDetails": "请填写必要的详细信息",
        "NotFoundError.UserDoesNotHaveAccessPrivileges": "用户没有访问权限",
        "NotFoundError.UserNotFoundOrNoLongerPartOfTheOrganization":
          "用户未找到或不再属于组织",
        "BadRequestError.MissingRequiredFields": "缺少必填字段",
        "InternalServerError.FailedToSendSMS": "无法发送短信",
        "NotFoundError.EmailConfigNotFound": "未找到电子邮件配置",
        "BadDigestError.FailedToDeleteAddress": "无法删除地址",
        "BadDigestError.FailedToUpdateAddress": "无法更新地址",
        "BadDigestError.FailedToUpdatePersonalInfo": "无法更新个人信息",
        "BadRequestError.UserOnlyAllowedToHaveMaximumOf5Addresses":
          "用户只允许拥有最多5个地址",
        "NotFoundError.AddressNotFound": "未找到地址",
        "NotFoundError.ArticleNotFoundOrNotAssociatedWithThisCompany":
          "文章未找到或与此公司无关",
        "NotFoundError.CampaignIDNotFound": "未找到活动ID",
        "NotFoundError.NoArticlesExistInThisCompanyId": "此公司ID中不存在文章",
        "NotFoundError.NoPointHistoryFound": "未找到积分历史",
        "NotFoundError.UserHasNotSubmittedAnyCampaignEntry":
          "用户尚未提交任何活动条目",
        "NotFoundError.UserIsNotAssociatedWithAnyCompanyId":
          "用户未关联任何公司ID",
        "NotFoundError.UserPersonalInfoNotFound": "未找到用户个人信息",
        "BadDigestError.FailedToCreateDeliveryAddress": "创建交付地址时出错",
        "BadDigestError.FailedToUpdateTheRedemptionStatus": "无法更新兑换状态",
        "BadDigestError.UserAlreadyHas5AddressesInTotal!":
          "用户已经有总共5个地址!",
        "BadDigestError.VoucherClickedMoreThanOnce!": "优惠券点击超过一次！",
        "NotFoundError.AddressNotFoundOrNotAssociatedWithTheUser":
          "未找到地址或与用户无关",
        "NotFoundError.PleaseProvideVoucherInfo": "请提供优惠券信息",
        "NotFoundError.VoucherDoesNotExistInTheCompanyRewardSystem":
          "公司奖励系统中不存在优惠券",
        "NotFoundError.VoucherNotFound": "未找到优惠券",
        "NotFoundError.VoucherRedemptionHistoryNotFound":
          "找不到优惠券兑换历史记录",
        "NotFoundError.AddressNotCreated": "地址未创建",
        "BadDigestError.FailToUpdatePaymentInformation": "无法更新付款信息",
        "BadDigestError.UserHasFilledUpThePaymentInformation":
          "用户已填写付款信息",
        "NotFoundError.NoCampaignInfoFoundYet": "尚未找到活动信息",
        "NotFoundError.NoPaymentDetailsFound": "找不到付款详情",
        "NotFoundError.QuestionnaireAssociatedWithThisCampaignIDNotFound":
          "与此活动ID关联的问卷未找到",
        "NotFoundError.VoucherAssociatedWithReceiptSubmissionNotFound!":
          "与收据提交关联的优惠券未找到!",
        "NotFoundError.VoucherNotFound!": "未找到优惠券!",
        "InternalError.ErrorImportingData": "导入数据时出错:'，错误",
        "BadRequestError.AddressNotFound!": "未找到地址!",
        "BadRequestError.CampaignEntryNotFound!": "未找到活动条目!",
        "BadRequestError.CampaignNotFound!": "未找到活动",
        "BadRequestError.EmailAlreadyExists": "电子邮件已存在",
        "BadRequestError.InvalidRequest.MissingRequiredFields.":
          "缺少必填字段.",
        "BadRequestError.InvalidRole": "无效角色",
        "BadRequestError.InvalidSelectedRewardIds.": "无效的选择奖励ID.",
        "BadRequestError.StaffCodeAlreadyExists": "员工代码已存在",
        "BadRequestError.UserAlreadyExists": "用户已存在",
        "ConflictError.CampaginIsNotActive.": "活动未激活",
        "ConflictError.ErrorCreatingCampaginEntry.": "创建活动条目时出错",
        "InternalServerError.FailedToCreateAppUser": "无法创建用户",
        "InternalServerError.FailedToCreateUser": "无法创建用户",
        "BadRequestError.AlreadyExpired!": "已过期",
        "BadRequestError.CompanyIdIsInvalid!": "公司ID无效!",
        "BadRequestError.IdIsInvalid!": "ID无效!",
        "BadRequestError.Inactive!": "无效!",
        "BadRequestError.InsufficientPoints.": "积分不足",
        "BadRequestError.MissingEmailConfig": "缺少电子邮件配置",
        "BadRequestError.NotFound!": "未找到!",
        "BadRequestError.OutOfStock!": "缺货",
        "BadRequestError.ShopItemNotFound!": "未找到商品!",
        "ConflictError.ErrorOnCreatingDeductPoint.": "创建扣点时出错",
        "ConflictError.ErrorOnDeductingPointFromUser.": "从用户扣点时出错",
        "ConflictError.ErrorUploadingFile.": "上传文件时出错",
        "InternalError.ErrorInUploadFile": "上传文件时出错",
        "InternalServerError.FailedToSendEmail": "无法发送电子邮件",
        "UnprocessableEntityError.MissingRequiredFields": "缺少必填字段",
        "BadRequestError.InsufficientStock.": "库存不足",
        "BadDigestError.CampaignEntryNotFound": "找不到活动条目",
        "BadRequestError.AddressIsRequired": "地址是必需的",
        "BadRequestError.CompanyUserIdIsInvalid!": "公司用户ID无效!",
        "BadRequestError.FormatNotSupported": "不支持的格式",
        "BadRequestError.OrderIdIsInvalid!": "订单ID无效!",
        "BadRequestError.OrderNotFound!": "找不到订单!",
        "BadRequestError.RetailerNotFound!": "未找到零售商",
        "BadRequestError.StoreAlreadyExist": "商店已存在",
        "BadRequestError.StoreCodeAlreadyExist": "商店代码已存在",
        "BadRequestError.TotalPointsDoesNotMatchWithTheCartSummary":
          "总积分与购物车摘要不匹配",
        "BadRequestError.UserIdIsInvalid!": "用户ID无效",
        "ConflictError.AddressNotFound!": "未找到地址!",
        "ConflictError.ErrorDeductingWalletPoints.": "扣除钱包积分时出错",
        "ConflictError.ErrorOnCreatingOrder.": "创建订单时出错",
        "ConflictError.ErrorOnCreatingOrderItem.": "创建订单项时出错",
        "ConflictError.ErrorOnUpdatingReward.": "更新奖励时出错",
        "ConflictError.InsufficientStock.": "库存不足",
        "ConflictError.ShopItem'sRewardNotFound.": "未找到商店商品的奖励",
        "ConflictError.ShopItemIsMissing": "商店物品丢失",
        "FailedDependencyError.UnableToGetRewardStocks.": "无法获取奖励库存",
        "BadRequestError.DSRNotFound!": "DSR未找到!",
        "BadRequestError.CartIdIsInvalid!": "购物车ID无效!",
        "BadRequestError.CartNotFound!": "找不到购物车！",
        "BadRequestError.CompanyNotFound!": "找不到公司！",
        "BadRequestError.CompanyUserNotFound!": "用户未找到!",
        "BadRequestError.Issue/sOnSelectedItems.": "选择的商品有问题。",
        "BadRequestError.ProvidedItemTypeCan'tBePurchase.":
          "提供的物品类型无法购买",
        "BadRequestError.SelectedReward(s)IsNotPartOfTheCampaign":
          "选择的奖励不是活动的一部分",
        "BadRequestError.UserNotFound!": "用户未找到!",
        "BadRequestError.CampaignRewardIsOutOfStock": "活动奖励已售完",
        "BadRequestError.PreviousCampaignEntryIsNotCancelled.":
          "先前的活动条目未取消。",
        "BadRequestError.SelectedRewardIdsMustBeAnArray":
          "选择奖励ID必须是数组",
        "BadRequestError.You'veAlreadyResubmittedForThisEntry.":
          "您已经重新提交过.",
        "ForbiddenError.YouAreNotAllowedToResubmitThisEntry.":
          "您不被允许重新提交",
        "BadRequestError.PreviousCampaignEntryIdIsRequired.":
          "需要先前的活动录入ID",
        "BadDigestError.DuplicateAddressFound": "发现重复地址",
        "BadDigestError.VoucherAlreadyExpired": "优惠券已过期",
        "BadRequestError.InvalidRequest.TokenTypeIsRequired.":
          "无效请求。需要令牌类型。",
        "BadRequestError.ThisSelectedRewardIsNotPartOfTheCampaign":
          "此选择的奖励不是活动的一部分",
        "BadRequestError.YouHaveReachedTheMaximumNumberOfEntries":
          "您已达到最大参与次数",
        "BadRequestError.InvalidRequest.RecipientFieldIsRequired.":
          "无效请求。需要接收者字段。",
      },
    };

    // check if backend error message
    const isBackendErrorMessage =
      error?.response?.data?.message && error?.response?.data?.name;
    const validLanguage = localStorage.getItem("language");

    if (isBackendErrorMessage && validLanguage) {
      const { message, name } = error?.response?.data;

      const key = `${name}.${toCamelCase(message)}`;
   
      const translatedMessage = dummyTransaltion[validLanguage] && dummyTransaltion[validLanguage][key];
      if (translatedMessage) {
        // console.log(dTranslate[key])
        error.response.data.message = translatedMessage;
      }
    }

    if (error?.response?.status === 401) {
      localStorage.removeItem("user-token");

      window.location.href = `/login${window.location.search}`;
    }

    return Promise.reject(error);
  }
);

export default axiosClient;
