const LanguageToggleBtn = ({ language, setLanguage }) => {
  return (
    <div
      style={{
        width: 35,
        height: 35,
        position: "relative",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <div
        style={{
          width: 35,
          height: 35,
          left: 0,
          top: 0,
          position: "absolute",
          background: "white",
          borderRadius: 6,
          border: "1px #D7D7D7 solid",
        }}
      >
        <p style={{ color: "black", position:'relative', bottom:'0.25rem' }} className="font-weight-600 font-14">{language}</p>
      </div>
    </div>
  );
};

export default LanguageToggleBtn;
