import { useTranslation } from "react-i18next";
import invoiceIcon from "../../images/invoiceIcon.png";
import PButton from "../PButton/PButton";
import UploadIcon from "../icons/UploadIcon";
import { useSelector } from "react-redux";

export default function UserUserUploadReceiptBanner({ onClick }) {
  const { userProfile } = useSelector((state) => state.userReducer);
  const userName = userProfile?.personalInfo?.name || "";
  const { t } = useTranslation();
  const getLanguage = localStorage.getItem("language");

  return (
    <div
      style={{
        width: "80%",

        margin: "0px 30px",
        padding: "4%",

        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap-reverse",
        alignItems: "center",

        minHeight: "100px",

        borderRadius: "5px",
        background: "#FBFAFC",
        boxShadow: "5px 5px 20px 0px rgba(60, 60, 67, 0.15)",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          gap: "10px",

          textAlign: "left",
        }}
      >
        <h3
          style={{
            margin: "10px",
          }}
        >
          {t("Hello")}, {userName}
        </h3>

        {getLanguage === "ENG" && (
          <p
            style={{
              margin: "10px",
            }}
          >
            Upload purchase receipts
            <br />
            to receive rewards!
          </p>
        )}
        {getLanguage === "BM" && (
          <p
            style={{
              margin: "10px",
            }}
          >
            Muat naik resit pembelian
            <br /> untuk menerima ganjaran!{" "}
          </p>
        )}
        {getLanguage === "CH" && (
          <p
            style={{
              margin: "10px",
            }}
          >
            传购物收据以获取奖励！​{" "}
          </p>
        )}

        <PButton
          style={{
            width: "30%",
            padding: "8px",
            marginLeft:'10px',
          }}
          onClick={onClick}
        >
          <UploadIcon />
          {t("UploadReceipt")}
        </PButton>
      </div>

      {/* INVOICE ICON */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          position: "relative",
        }}
      >
        <img
          alt="Upload Receipt Icon"
          src={invoiceIcon}
          style={{
            maxWidth: "80px",
            minWidth: "80px",
            width: "100%",
          }}
        />
      </div>
    </div>
  );
}
