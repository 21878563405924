import { Button } from "antd";

export default function PButton(props) {
	const { children, style,...rest} = props

	const btnStyle = {
		minWidth: "fit-content",
		// minHeight: "32px",
		padding: 8,
		background: "#63BC46",
		border: "#63BC46",
		boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",

		display: "flex",
		flexDirection: "row",
		alignContent: "center",
		alignItems: "center",
		justifyContent: "center",
		gap: "5px",

		color: "#FBFAFC",
		fontSize: 14,
		fontFamily: "Hero New",
		fontWeight: "500",
		marginLeft:'10px',


		...style,
	};

	return (
		<Button style={btnStyle} {...rest}>
			{children}
		</Button>
	);
}
