import tng from "../../images/tng.png";
import grab from "../../images/grab.png";
import spay from "../../images/spay.png";
import React from "react";
import { useTranslation } from "react-i18next";


const rewardImgSrc = {
	TNG: tng,
	GRAB: grab,
	SPAY: spay,
};
const getRewardImg = (type) => rewardImgSrc[type];

const EnvelopHeaderRewardAmount = ({ reward, resizeByLengthFunc }) => {
	const {t} = useTranslation();

	const RewardImg = ({ type, defaultSize }) => (
		<div
			style={{
				width: resizeByLengthFunc(defaultSize),
				padding: "0px 2px",
			}}
		>
			<img
				src={getRewardImg(type)}
				alt="Rewards Logo"
				style={{
					width: "100%",
					height: "100%",
				}}
			/>
		</div>
	);

	const RewardAmountText = ({ defaultSize }) => (
		<p
			style={{
				margin: "0",

				color: "#285EAC",
				fontFamily: "Open Sans",
				fontSize: resizeByLengthFunc(defaultSize),
				fontStyle: "normal",
				fontWeight: 700,
				letterSpacing: "0.36px",
			}}
		>
			{reward.amount}
		</p>
	);

	const RewardDescriptionText = ({ defaultSize }) => (
		<p
			style={{
				margin: "-5px 0 0",

				color: "#285EAC",
				fontFamily: "Open Sans",
				fontSize: resizeByLengthFunc(localStorage.getItem('language')==="BM" ? 14 : defaultSize),
				fontStyle: "normal",
				fontWeight: 700,
				letterSpacing: "0.21px",
				textAlign:'center'
			}}
		>
			{reward.description === "RELOAD PIN" ?t('RELOAD PIN'):reward.description === "E-Voucher" ? t('EVoucher'): reward.description}
		</p>
	);


	if (reward?.types && reward?.types.length > 0) {
		return (
			<div
				style={{
					margin: "0 auto",

					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<RewardAmountText defaultSize={44} />
				<RewardDescriptionText defaultSize={12} />

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyItems: "center",
						alignItems: "center",
					}}
				>
					{React.Children.toArray(reward.types.map((type) => <RewardImg type={type} defaultSize={40} />))}
				</div>
			</div>
		);
	}

	return (
		<div
			style={{
				margin: "0 auto",

				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<RewardImg type={reward.type} defaultSize={76} />

			<div
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "start",
				}}
			>
				<RewardAmountText defaultSize={34} />
				<RewardDescriptionText defaultSize={20} />
			</div>
		</div>
	);
};

export default EnvelopHeaderRewardAmount;
