import React, { useRef, useState, useEffect } from "react";
import { Button, Input, message } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserEligibleCNYCampaignByChannelIfNotExist } from "../redux/campaign/campaignActions";
import upload_img from "../images/upload-img-icon.png";
import primaryBgImg from "../images/testing.png";
import textLogo from "../images/textLogo.png";
import UserChannelValidationModal from "../components/UserChannelValidationModal/UserChannelValidationModal";
import axiosClient from "../utils/axiosClient";
import ReceiptUploadSuccessModal from "../components/ReceiptUploadSuccessModal/ReceiptUploadSuccessModal";
import { fetchUserProfileIfNotExist } from "../redux/user/userActions";
import {
  HALEON_CNY_CAMPAIGNS,
} from "../consts/haleonCnyCampaignsChannels";

const WATSON_CAMPAIGN_ID = HALEON_CNY_CAMPAIGNS.WATSONS;

const WATSON_REWARD_OPTIONS = [
  {
    minReciptAmount: 68,
    maxReciptAmount: 127,
    rewardId: process.env.REACT_APP_CNY_CAMPAIGN_WATSONS_MIN_68_REWARD_ID,
  },
  {
    minReciptAmount: 128,
    rewardId: process.env.REACT_APP_CNY_CAMPAIGN_WATSONS_MIN_128_REWARD_ID,
  },
];

const Upload = ({ t }) => {
  const navigate = useNavigate();
  const uploadref = useRef();
  const [imageFile, setImageFile] = useState();
  const [imageUrl, setImageUrl] = useState("");

  const [errorMessage, setErrorMessage] = useState({});
  const getLanguage = localStorage.getItem("language");

  const dispatch = useDispatch();

  const { userProfile, error: userRError } = useSelector(
    (state) => state.userReducer
  );
  const { campaign, error: camRError } = useSelector(
    (state) => state.campaignReducer
  );

  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [receiptAmount, setReceiptAmount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [userSelectedRewardId, setUserSelectedRewardId] = useState();
  const [redirectUserToResubmisisonPage, setRedirectUserToResubmisisonPage] = useState(false)

  const userChannel = userProfile?.personalInfo?.registerSource?.channel;

  const [prevCamEntry, setPrevCamEntry] = useState();

  const [searchParams] = useSearchParams();
  const prevCamEntryId = searchParams.get("prevCamEntryId");
  const isResubmit = prevCamEntry && prevCamEntryId;

  useEffect(() => {
    if (userRError) {
      message.error(userRError || "An error occurred while fetching data.");
    }
  }, [userRError]);

  useEffect(() => {
    if (camRError) {
      message.error(camRError || "An error occurred while fetching data.");
    }
  }, [camRError]);

  useEffect(() => {
    dispatch(fetchUserProfileIfNotExist());
  }, [dispatch]);

  useEffect(() => {
    if (!userChannel) return;
    dispatch(fetchUserEligibleCNYCampaignByChannelIfNotExist(userChannel));
  }, [dispatch, userChannel]);

  const handleDisplayImage = (e) => {
    let render = new FileReader();
    setErrorMessage({ ...errorMessage, submit: null });
    const supportedImgFormat = ["image/jpeg", "image/jpg", "image/png"];

    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];

      if (supportedImgFormat.includes(selectedFile.type)) {
        setImageFile(selectedFile);
        render.readAsDataURL(selectedFile);
        render.onload = (res) => {
          setImageUrl(res.target.result);
        };
      } else {
        setErrorMessage("Please upload a JPEG, JPG, or PNG image only!");
        setTimeout(() => {
          setErrorMessage("");
        }, 1000);
      }
    }
  };

  const isNationWide = campaign?.id === process.env.REACT_APP_CNY_CAMPAIGN_NATIONWIDE_ID;
  const isCustomInputReceiptAmount = campaign?.id === WATSON_CAMPAIGN_ID;

  useEffect(() => {
    if (!prevCamEntryId || prevCamEntryId?.length !== 36) return;
    if (!prevCamEntry) {
      setIsLoading(true);
      // uuid have 26 length
      axiosClient
        .get(`/campaign-entries/${prevCamEntryId}`)
        .then((res) => {
          if (res.data.data && res.data.data.id === prevCamEntryId) {
            setPrevCamEntry(res.data.data);
          } else {
            setErrorMessage({
              ...errorMessage,
              submit: "Invalid previous entry id.",
            });
          }
        })
        .catch((err) => {
          console.error("Error occurred:", err);
          setErrorMessage({
            ...errorMessage,
            submit: "An error occurred while processing data.",
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setErrorMessage({
        ...errorMessage,
        submit: "Invalid previous entry id.",
      });
    }
  }, []);

  useEffect(() => {
    if (!prevCamEntry) return;

    if (prevCamEntry?.selectedRewardIds?.length) {
      setUserSelectedRewardId(prevCamEntry?.selectedRewardIds[0]);
    }

    if (prevCamEntry?.campaignInput?.receiptAmount) {
      setReceiptAmount(prevCamEntry?.campaignInput?.receiptAmount);
    }
  }, [prevCamEntry]);

  const onSubmit = async (e) => {
    setErrorMessage({
      ...errorMessage,
      submit: null,
    });

    if (!imageFile) {
      setErrorMessage({
        ...errorMessage,
        submit: "Please upload your receipt image!",
      });
      return;
    }

    const data = new FormData();
    data.append("image", imageFile);

    let _rewardId;

    // WATSON CAMPAIGN REWARD
    if (receiptAmount && campaign?.id === WATSON_CAMPAIGN_ID) {
      // NOTE: doesn't handle checking stock yet
      const rewardOption = WATSON_REWARD_OPTIONS.find((option) => {
        if (option.minReciptAmount && option.maxReciptAmount) {
          return (
            receiptAmount >= option.minReciptAmount &&
            receiptAmount <= option.maxReciptAmount
          );
        } else if (option.minReciptAmount) {
          return receiptAmount >= option.minReciptAmount;
        }
      });
      if (rewardOption?.rewardId) {
        // check stock
        _rewardId = rewardOption?.rewardId;
        // const isOutOfStock = true;
        // if (isOutOfStock) {
        // 	setErrorMessage({
        // 		...errorMessage,
        // 		submit: "Reward is out of stock.",
        // 	});
        // 	return;
        // }
      } else {
        setErrorMessage({
          ...errorMessage,
          submit:
            "Could not find reward for this receipt amount. Make sure to submit the correct receipt amount.",
        });
        return;
      }
    }
    // NATIONWIDE CAMPAIGN REWARD
    else if (userSelectedRewardId) {
      _rewardId = userSelectedRewardId;
    }

    // if there optional reward, rewardId must be present
    if (campaign?.rewardOptionIds?.length && !_rewardId) {
      setErrorMessage({
        ...errorMessage,
        submit: "Please select your reward!",
      });
      return;
    }

    setIsLoading(true);

    let entryData = {
      campaignId: campaign.id,
      type: "RECEIPT",
      selectedRewardIds: _rewardId ? [_rewardId] : [],
      data: {
        ...(receiptAmount && {
          campaignInput: {
            receiptAmount: receiptAmount,
          },
        }),
        // for resubmit entry
        ...(prevCamEntry &&
          isResubmit && {
            prevCampaignEntryId: prevCamEntry.id,
          }),
      },
    };

    try {
      let isValidEntry = false;
      if (isResubmit) {
        // Check resubmit-entry validity, to prevent uploading image if entry is invalid
        const validityResponse = await axiosClient.post(
          `/haleon-cny/campaigns/entries/resubmit-validity`,
          {
            prevCampaignEntryId: prevCamEntry.id,
          }
        );

        if (validityResponse.data.isValid) {
          isValidEntry = true;
        }
      } else {
        // Check entry validity, to prevent uploading image if entry is invalid
        const validityResponse = await axiosClient.post(
          `/haleon-cny/campaigns/entries/validity`,
          entryData
        );

        if (validityResponse.data.isValid) {
          isValidEntry = true;
        }
      }

      if (!isValidEntry) {
        throw new Error("Invalid entry.");
      }

      // Upload image
      const uploadImageResponse = await axiosClient.post(
        `/campaigns/${campaign.id}/upload-image`,
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (
        uploadImageResponse.data.data.url &&
        uploadImageResponse.data.data.key
      ) {
        // Update entry data with image data
        entryData = {
          ...entryData,
          data: {
            ...entryData.data,
            imageData: {
              url: uploadImageResponse.data.data.url,
              key: uploadImageResponse.data.data.key,
            },
          },
        };

        // Create entry
        const entryResponse = await axiosClient.post(
          `/haleon-cny/campaigns/entries`,
          entryData
        );
        if (entryResponse.data.id) {
          // Set upload success
          setUploadSuccess(true);
        }
      } else {
        throw new Error("Error on uploading image.");
      }
    } catch (error) {
      console.error("Error occurred:", error.message);
      // handle if error response message is "Previous campaign entry is required for resubmission."
      if (error.response && error.response.data && error.response.data.message) { 
        if (error.response.data.message === "Previous campaign entry is required for resubmission.") {
          setRedirectUserToResubmisisonPage(true)

          return;
        }
      }
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setErrorMessage({
          ...errorMessage,
          submit: error.response.data.message,
        });
      } else {
        setErrorMessage({
          ...errorMessage,
          submit: "An error occurred while processing data.",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        minWidth: "100vw",
        minHeight: "100vh",
        backgroundImage: `url(${primaryBgImg})`,
        backgroundSize: "100%",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",

        opacity: isLoading ? 0.5 : 1,
        pointerEvents: isLoading ? "none" : "auto",
      }}
    >
      {/* VALIDATE USER CHANNEL */}
      <UserChannelValidationModal
        redirectRoutePath="/upload"
        checkCampaignPeriod={true}
      />

      {/* UPLOAD SUCCESS MODAL */}
      <ReceiptUploadSuccessModal
        open={uploadSuccess}
        onClose={() => {
          setUploadSuccess(false);
          navigate("/home", {
            state: {
              promptType: "staytuned",
            },
          });
        }}
      />

      <div className="rewards-wrap">
        <div>
          <img className="header-text-img" src={textLogo} alt="" />
        </div>

        <p className="t-white font-weight-600 font-26">{t("UploadReceipt")}</p>
        {imageUrl ? (
          <div className="mt-3">
            <div
              style={{
                margin: "0 10% 0 10%",
                overflow: "hidden",
                height: "20vh",
              }}
            >
              <img
                src={imageUrl}
                alt="receipt"
                onClick={() => uploadref.current.click()}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        ) : (
          <div
            className="upload-img-container"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              lineHeight: 1,
            }}
            onClick={() => uploadref.current.click()}
          >
            <img
              src={upload_img}
              alt="upload-img-icon"
              style={{ width: "20%" }}
            />
            <p>
              <span className="font-18 t-white">
                {t("Upload receipt photo")}
              </span>
              <br />
              <span style={{ fontSize: "10px" }} className="t-white">
                {t("Supported image format")}
              </span>
            </p>
          </div>
        )}
        <p
          className="font-12 font-weight-400"
          style={{
            margin: "2% 10% 0 10%",
            textAlign: getLanguage === "CH" ? "center" : "left",
          }}
        >
          {t("notes")}
        </p>
      </div>
      <div>
        <input
          type="file"
          accept="image/jpeg, image/png, image/jpg"
          name="receiptImage"
          ref={uploadref}
          onChange={(e) => handleDisplayImage(e)}
          style={{ width: "0px", height: "0px", opacity: "0" }}
          required
        />
      </div>

      {/* Here's to display depends on the channel */}
      {isNationWide && (
        <>
          <div className="mt-2 t-white font-weight-600 font-16">
            {`${isResubmit ? '' : t("Select your reward")}`}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "7%",
              justifyContent: "center",
              marginTop: "5%",
            }}
          >
            {React.Children.toArray(
              campaign?.rewards?.map((reward) => {
                const isOutOfStock = reward?.qtyRemaining <= 0;
                const isValidToSelect = !isOutOfStock && reward?.isValid;

                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      opacity:
                        isValidToSelect || userSelectedRewardId === reward?.id
                          ? 1
                          : 0.5,
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        width: "70px",
                        height: "70px",
                      }}
                    >
                      <img
                        src={reward?.imageUrl}
                        alt={reward?.name}
                        style={{
                          width: "auto",
                          height: "100%",
                        }}
                        onClick={() =>
                          isResubmit
                            ? () => {} //[2023-12-05] we don't allow user to change reward for resubmit
                            : isValidToSelect &&
                              setUserSelectedRewardId(reward?.id)
                        }
                      />
                    </div>
                    {isOutOfStock && (
                      <div
                        style={{
                          padding: "4px",
                          borderRadius: "10px",
                          background: "white",
                        }}
                      >
                        <span
                          style={{
                            color: "red",
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        >
                          Out of Stock
                        </span>
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </>
      )}

      {isCustomInputReceiptAmount && (
        <div
          style={{
            margin: "0 10% 0 10%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label
            style={{
              color: "#FFF",
              fontFamily: "Hero New",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
              textAlign: "left",
              marginBottom: "8px",
            }}
          >
            {t("TotalAmount")}
          </label>
          <Input
            type="number"
            placeholder={t("ReceiptPlaceholder")}
            value={receiptAmount}
            style={{
              padding: "16px",
              fontSize: "14px",
              borderRadius: "4px",
              border: "1px solid var(--light-color-base-tertiary-dark, #DDD)",
              background: "#FFF",
              boxShadow: "0px 0px 7.6px 0px rgba(0, 0, 0, 0.25) inset",
            }}
            disabled={isResubmit}
            onChange={(e) => {
              let inputValue = e.target.value;
              const regexReceiptAmount = /^\d*\.?\d{0,2}$/;
              if (regexReceiptAmount.test(inputValue)) {
                setReceiptAmount(inputValue);
              }
            }}
          />
        </div>
      )}

      <div style={{ margin: "10% 10% 5% 10%" }}>
        {/* SHOW ERROR MESSAGE */}
        {Object.values(errorMessage).filter((_v) => _v).length > 0 && (
          <div
            style={{
              margin: "10px auto",
              background: "white",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <p
              style={{
                color: "red",
                fontSize: "12px",
                margin: "0",
                fontWeight: "bold",
              }}
            >
              {Object.values(errorMessage)?.map((err, index) => (
                <span key={index}>{err}</span>
              ))}
            </p>
          </div>
        )}

        <Button
          style={{
            width: "100%",
            textAlign: "center",
            borderRadius: "5px",
            background: "#63BC46",
            border: "none",
            color: "#FFF",
            fontWeight: "700",
            height: "40px",
          }}
          onClick={onSubmit}
        >
          {t("Submit Receipt")}
        </Button>

        <Button
          style={{
            width: "100%",
            textAlign: "center",
            borderRadius: "5px",
            background: "#D4D4D4",
            border: "none",
            color: "white",
            fontWeight: "700",
            height: "40px",
            marginTop: "3%",
          }}
          onClick={() => navigate("/home")}
        >
          {t("Back")}
        </Button>
      </div>
    </div>
  );
};

export default Upload;
