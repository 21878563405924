import primaryBareBgImg from "../images/primary-bare_bg.png";
import primaryFrameImg from "../images/primary_frame.png";
import InfoIcon from "../components/icons/InfoIcon";
import RewardIcon from "../components/icons/RewardIcon";
import HeadingTitle from "../components/HeadingTitle/HeadingTitle";
import UserUploadReceiptBanner from "../components/UserUploadReceiptBanner/UserUploadReceiptBanner";
import PButton from "../components/PButton/PButton";
import React, { useEffect, useState } from "react";
import Questionnaire from "../components/Modal/Questionnaire";
import { useNavigate } from "react-router-dom";
import StayTuned from "../components/Modal/StayTuned";
import { useDispatch, useSelector } from "react-redux";
import { message, Modal, Button } from "antd";
import { fetchUserEligibleCNYCampaignByChannelIfNotExist } from "../redux/campaign/campaignActions";
import EWalletCreditRedeem from "../components/EWalletCreditRedeem/EWalletCreditRedeem";
import UserChannelValidationModal from "../components/UserChannelValidationModal/UserChannelValidationModal";
import axiosClient from "../utils/axiosClient";
import channel1 from "../pdf/Haleon CNY 2024 (MY)_[Nationwide]Consumer TC_Haleon Start a Healthy New Year.pdf";
import channel2 from "../pdf/Haleon CNY 2024_[Watsons]Consumer TC_Haleon Start a Healthy New Year.pdf";
import channel3 from "../pdf/Haleon CNY 2024 (MY)_[Guardian]Consumer TC_Haleon Start a Healthy New Year.pdf";
import channel4 from "../pdf/Haleon CNY 2024 (MY)_[Chain IP _ Ecomm]Consumer TC_Haleon Start a Healthy New Year.pdf";
import alertBell from "../images/alertBell.svg";
import { fetchUserProfileIfNotExist } from "../redux/user/userActions";
import { useTranslation } from "react-i18next";

const PrimaryBtns = ({ pdfLink }) => {
  const navigate = useNavigate();
  // const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();

  // const handleOpenModal = () => {
  //   setModalVisible(true);
  // };

  // const handleCaloseModal = () => {
  //   setModalVisible(false);
  // };
  const primaryBtnContainerStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "10px auto",
  };

  return (
    <div style={primaryBtnContainerStyle}>
      {/* <img src={stayTune} alt=""/> */}
      <div
        style={{ display: "flex", justifyContent: "space-between", gap: "5px" }}
      >
        <PButton onClick={() => navigate("/rewards")}>
          <RewardIcon />
          {t("Rewards")}
        </PButton>
        <PButton>
          <InfoIcon />
          <a href={pdfLink} target="_blank" rel="noopener noferrer">
            {t("Terms & Conditions")}
          </a>
        </PButton>
      </div>
    </div>
  );
};

export default function Home({ t }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [displayQues, setDisplayQues] = useState(false);
  const [displayPopUp, setDisplayPopUp] = useState(false);
  const [questionSubmitted, setQuestionSubmitted] = useState(false);
  // const [submissions, setSubmissions] = useState([]);
  const [alertRejectReceipt, setAlertRejectReceipt] = useState(false);
  const [prevSubmissionId, setPrevSubmissionId] = useState();

  const {
    userProfile,
    isLoading: userRLoading,
    error: userRError,
  } = useSelector((state) => state.userReducer);
  const {
    campaign,
    isLoading: camRLoading,
    error: camRError,
  } = useSelector((state) => state.campaignReducer);

  const userRegisteredChannel =
    userProfile?.personalInfo?.registerSource?.channel;

  useEffect(() => {
    const _error = userRError || camRError;
    // must be string
    if (_error && typeof _error === "string") {
      message.error(_error);
    }
  }, [userRError, camRError]);

  useEffect(() => {
    dispatch(fetchUserProfileIfNotExist());
  }, []);

  // FETCH USER ELIBIGLE CAMPAIGN DETAILS
  useEffect(() => {
    if (userProfile) {
      const userChannel = userProfile?.personalInfo?.registerSource?.channel;
      if (!userChannel) {
        console.error(t("user missing registerSource channel"));
        return;
      }
      dispatch(fetchUserEligibleCNYCampaignByChannelIfNotExist(userChannel));
    }
  }, [dispatch, userProfile]);

  useEffect(() => {
    const checkQuestionnaire = async () => {
      try {
        const res = await axiosClient.get("/campaign/questionnaire/get", {
          params: { sourceId: campaign?.id },
        });
        if (res && res.data.fetchQuestion) {
          setDisplayQues(false);
          setQuestionSubmitted(true);
        } else {
          // Introduce a delay before showing the questionnaire
          setTimeout(() => {
            setDisplayQues(true);
          }, 500); // Adjust the delay time in milliseconds as needed
          setQuestionSubmitted(false);
        }
      } catch (err) {
        console.error("error", err);
      }
    };

    // Check if the questionnaire has been submitted before
    if (!questionSubmitted) {
      checkQuestionnaire();
    }
  }, [campaign?.id, questionSubmitted]);

  const handleOnUploadReceipt = async () => {
    // check campaign period
    if (campaign && campaign?.startAt && campaign?.endAt) {
      const now = new Date();
      const startAt = new Date(campaign.startAt);
      const endAt = new Date(campaign.endAt);
      if (now < startAt) {
        message.error(t("Campaign not started yet."));
        return;
      }
      if (now > endAt) {
        message.error(t("Campaign has ended."));
        return;
      }
    } else if (
      userRegisteredChannel &&
      campaign &&
      !campaign?.config?.channels?.includes(userRegisteredChannel)
    ) {
      message.error(
        t(
          "Campaign not available for your channel. Will redirect to you campaign page."
        )
      );
      dispatch(
        fetchUserEligibleCNYCampaignByChannelIfNotExist(userRegisteredChannel)
      );
    }
    await getReceiptSubmissions();
    // navigate(`/upload${window.location.search}`);
  };

  // useEffect(() => {
  const getReceiptSubmissions = async () => {
    if (!campaign?.id) {
      return;
    }
    try {
      const res = await axiosClient.get("/submissions", {
        params: { campaignId: campaign.id },
      });
      if (res && res.data && res.data.getFinalSubmissionInfo) {
        const finalSubmissions = res.data.getFinalSubmissionInfo;
        const firstRejectedSubmission = finalSubmissions.find(
          (submission) => submission.getSubmissionInfo.status === "CANCELLED"
        );
        if (firstRejectedSubmission) {
          const firstRejectedReceiptId =
            firstRejectedSubmission.getSubmissionInfo.id;
          setAlertRejectReceipt(true);
          setPrevSubmissionId(firstRejectedReceiptId);
        } else {
          navigate(`/upload${window.location.search}`);
          setAlertRejectReceipt(false);
        }
      }
    } catch (e) {
      console.error("Error fetching submissions", e);
    }
  };
  // getReceiptSubmissions();
  // }, [campaign]);

  const isLoading = userRLoading || camRLoading;

  const [pdfLink, setPdfLink] = useState();

  useEffect(() => {
    if (campaign?.id === process.env.REACT_APP_CNY_CAMPAIGN_NATIONWIDE_ID) {
      setPdfLink(channel1);
    } else if (campaign?.id === process.env.REACT_APP_CNY_CAMPAIGN_WATSONS_ID) {
      setPdfLink(channel2);
    } else if (
      campaign?.id === process.env.REACT_APP_CNY_CAMPAIGN_GUARDIAN_ID
    ) {
      setPdfLink(channel3);
    } else if (campaign?.id === process.env.REACT_APP_CNY_CAMPAIGN_CBALS_ID) {
      setPdfLink(channel4);
    }
  }, [campaign]);

  return (
    <div
      className="pageContainer"
      style={{
        width: "100%",
        minWidth: "280px",
        minHeight: "100vh",

        backgroundImage: `url(${primaryBareBgImg})`,
        backgroundSize: "100%",
        backgroundRepeat: "repeat",
        backgroundColor: "red",
        backgroundPosition: "top center",
        position: "relative",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${primaryFrameImg})`,
          backgroundSize: "96%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",

          opacity: isLoading ? 0.9 : 1,
          pointerEvents: isLoading ? "none" : "auto",

          width: "100%",
          height: "100%",

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* VALIDATE USER CHANNEL */}
        <UserChannelValidationModal redirectRoutePath="/home" />

        {/* HEADER */}
        <HeadingTitle />

        {/* UPLOAD RECEIPT */}
        <UserUploadReceiptBanner onClick={handleOnUploadReceipt} />

        {/* PRIMARY BUTTONS */}
        <PrimaryBtns pdfLink={pdfLink} />

        {/*E-WALLET REDEEM BASED ON RESPECTIVE CAMPAIGN */}
        <div
          style={{
            flex: 1,
            width: "100%",
            marginTop: "30px",
          }}
        >
          <EWalletCreditRedeem
            campaign={campaign}
            onClickRedeemNow={handleOnUploadReceipt}
          />
        </div>

        {displayQues && !questionSubmitted ? (
          <Questionnaire
            displayQues={displayQues}
            setDisplayQues={setDisplayQues}
            channel={campaign?.id}
          />
        ) : null}

        <StayTuned
          displayPopUp={displayPopUp}
          setDisplayPopUp={setDisplayPopUp}
        />

        <Modal
          open={alertRejectReceipt}
          centered
          header={null}
          footer={null}
          closable={true}
          onCancel={() => setAlertRejectReceipt(false)}
          width={300}
        >
          <div className="text-center mt-1">
            <img
              src={alertBell}
              alt="alert_bell"
              style={{ width: "55px", height: "55px" }}
            />
            <p style={{ fontWeight: "700", fontSize: "20px" }}>
              {t("Submission Update")}
            </p>
            <p
              style={{
                fontSize: "15px",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              {t("Reject Update")}
            </p>
            <Button
              style={{
                width: "100%",
                textAlign: "center",
                borderRadius: "10px",
                background: "#02BC7D",
                border: "none",
                color: "white",
                fontWeight: "600",
                height: "40px",
                fontSize: "18px",
              }}
              onClick={() =>
                navigate(`/upload?prevCamEntryId=${prevSubmissionId}`)
              }
            >
              {t("Resubmit Now")}
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
}
