/* eslint-disable jsx-a11y/anchor-is-valid */
export default function NotFoundPage() {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				height: "100vh",
				width: "100vw",
				textAlign: "center",

				fontFamily:'monospace',
				textTransform: "uppercase",
				fontWeight: "bold",

				backgroundColor: "#f1f1f1",
			}}
		>
			<h1 style={{
				fontSize: "6rem",
				margin: 0,
			}}>404</h1>
			<p style={{
				fontSize: "1.2rem",
				margin: '0 0 20px',
			}}>Page not found</p>
			<a href="#" onClick={() => window?.history?.back() }>Go back to previoue page</a>
			
		</div>
	);
}
