import { SET_USER_PROFILE, SET_USER_ERROR, SET_USER_LOADING, SET_USER_SUBMISSIONS, } from "./userActionTypes";

const initialState = {
	userProfile: null,
	isLoading: false,
	error: null,
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_USER_LOADING:
			return {
				...state,
				isLoading: action.payload,
			};
		case SET_USER_PROFILE:
			return {
				...state,
				isLoading: false,
				userProfile: action.payload,
			};
		case SET_USER_ERROR:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			};

		default:
			return state;
	}
};

export default userReducer;
