import { Button } from "antd";
import Modal from "antd/es/modal/Modal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function UserChannelValidationModal({ redirectRoutePath = "/home", checkCampaignPeriod = false }) {
	const navigate = useNavigate();
	const {t} = useTranslation();
	const [searchParams] = useSearchParams();
	const routeChannel = searchParams.get("channel");

	const { userProfile } = useSelector((state) => state.userReducer);
	const userChannel = userProfile?.personalInfo?.registerSource?.channel;

	const { campaign } = useSelector((state) => state.campaignReducer);

	const [modalData, setModalData] = useState({
		open: false,
		closeBtn: {
			text: "OK",
			onClick: () => {
				setModalData({ open: false });
			},
		},
	});

	// check user channel with user's registerSourceChannel
	useEffect(() => {
		// check if user have channel
		if ( userProfile && !userChannel && !window.location.pathname.includes("/home")) {
			setModalData({
				open: true,
				closeBtn: {
					text: "OK",
					onClick: () => {
						setModalData();
						navigate(`/home${window.location.search}`);
					},
				},
				context: t("You're not eligible for this campaign. you'll be redirected to your campaign page."),
			});
		}

		// check if route channel and user channel is different
		if (userChannel && routeChannel && !modalData?.open) {
			if (userChannel && userChannel !== (routeChannel).toUpperCase()) {
				// change channel value to user's channel and pass back the rest of the query string
				const redirectUrl = `${redirectRoutePath}?channel=${userChannel}`;
				setModalData({
					open: true,
					closeBtn: {
						text: "OK",
						onClick: () => {
							setModalData();
							navigate(redirectUrl);
						},
					},
					context: t("You're not eligible for this campaign. you'll be redirected to your campaign page."),
				});
			}
		}
	}, [navigate, routeChannel, userChannel, modalData, redirectRoutePath,userProfile]);

	// check user channel with fetched campaign
	useEffect(() => {
		if (checkCampaignPeriod && campaign && campaign?.startAt && campaign?.endAt && !modalData?.open) {
			const now = new Date();
			const startAt = new Date(campaign.startAt);
			const endAt = new Date(campaign.endAt);
			const _errorMessage = now < startAt ? "Campaign not started yet." :
				now > endAt ? "Campaign has ended." : null;
			
			if (!_errorMessage) return;
			
			setModalData({
				open: true,
				closeBtn: {
					text: "OK",
					onClick: () => {
						setModalData();
						navigate('/home' + window.location.search);
					},
				},
				context: _errorMessage,
			});
		}
	}, [navigate, routeChannel, campaign, modalData, redirectRoutePath,checkCampaignPeriod,userProfile, userChannel]);

	return (
		<>
			<Modal
				centered
				closable={false}
				footer={
					modalData?.closeBtn ? (
						<Button
							type="primary"
							style={{
								textAlign: "center",
								margin: "0 auto",
							}}
							onClick={() => {
								modalData?.closeBtn?.onClick?.();
							}}
						>
							{modalData?.closeBtn?.text || "OK"}
						</Button>
					) : null
				}
				open={modalData?.open}
			>
				<div
					style={{
						textAlign: "center",
						fontSize: "18px",
						fontWeight: "bold",
						color: "#000",
						marginBottom: "10px",
					}}
				>
					{modalData?.context}
				</div>
			</Modal>
		</>
	);
}
