import React from "react";
import "./modal.css";

const StayTuned = ({displayPopUp, setDisplayPopUp}) => {
    if (!displayPopUp) {
        return null; 
      }
  return (
    <div className="frame">
      <div className="overlap">
        <div className="overlap-group">
          <img className="vector" alt="Vector" src="https://c.animaapp.com/7klqqgt2/img/vector-2.svg" />
          <img className="img" alt="Vector" src="https://c.animaapp.com/7klqqgt2/img/vector-1.svg" />
          <div className="div">
            <img className="group" alt="Group" src="https://c.animaapp.com/7klqqgt2/img/group-22717@2x.png" />
            <div className="div-2">
              <div className="div-3">
                <div className="div-wrapper">
                  <p className="text-wrapper">Something exciting is on the way.</p>
                </div>
                <div className="overlap-group-wrapper">
                  <div className="overlap-group-2">
                    <div className="div-wrapper-2" onClick = {() => setDisplayPopUp(false)}>
                      <div className="text-wrapper-3">STAY TUNED</div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="p">Stay Tuned for upcoming exciting rewards</p>
            </div>
          </div>
        </div>
        <div className="text-wrapper-4">STAY TUNED</div>
      </div>
      <div className="x" onClick={() => setDisplayPopUp(false)}>
        <img className="vector-2" alt="Vector" src="https://c.animaapp.com/7klqqgt2/img/vector.svg" />
      </div>
    </div>
  );
};


export default StayTuned;