import { Modal, Button } from "antd";
import tnc_black from "../../images/tnc_black.png";
import tnc_red from "../../images/tnc_red.png";
import { useTranslation } from "react-i18next";

const TnC = ({ tnc, setTnc, type }) => {
  const {t} = useTranslation();
  return (
    <div>
      {(type === "TNG" || type === "GRABPAY" || type === "SPAY") && (
        <Modal open={tnc} centered footer={null} closable={false}>
          <div className="text-center">
            <img
              style={{ width: "50px", height: "65px" }}
              src={type === "TNG" ? tnc_black : tnc_red}
              alt="tnc_icon"
            />
            <div className="mt-1" style={{ lineHeight: "40px" }}>
              <h3 className="mt-2 font-weight-600 font-20">
               {t('Terms & Conditions')}
              </h3>
            </div>
            {type === "TNG" && (
              <ul
                style={{
                  padding: "0 15px",
                  textAlign: "justify",
                  textJustify: "auto",
                }}
              >
                <li className="my-1 font-weight-400 font-12">
                  {" "}
                  {t('TNG_TNC_I')}
                </li>
                <li className="my-1 font-weight-400 font-12">
                {t('TNG_TNC_II')}
                </li>
                <li className="my-1 font-weight-400 font-12">
                {t('TNG_TNC_III')}
                </li>
                <li className="my-1 font-weight-400 font-12">
                {t('TNG_TNC_IV')}{" "}
                </li>
                <li className="my-1 font-weight-400 font-12">
                {t('TNG_TNC_V')}{" "}
                  <span style={{ color: "red" }}>{t("31 Mar 2024")}</span>
                </li>
              </ul>
            )}
            {type === "SPAY" && (
              <ul
                style={{
                  padding: "0 15px",
                  textAlign: "justify",
                  textJustify: "auto",
                  lineHeight: "131.4%",
                }}
                className="font-weight-500 font-14"
              >
                <li className="my-1">
                  {" "}
                  {t('SPAY_TNC_I')}
                </li>
                - {t('SPAY_username')}
                <br />
                - {t("SPAY_fullName")} <br />
                - {t('SPAY_NRIC')} <br />
                &nbsp;&nbsp;&nbsp;- {t('SPAY_phone')}
                <li className="my-1">
                  {t('SPAY_TNC_II')}
                </li>
                <li className="my-1">
                  {t('SPAY_TNC_III')}
                </li>
                <li className="my-1">
                  {t('SPAY_TNC_IV')}
                </li>
              </ul>
            )}
            {type === "GRABPAY" && (
              <ul
                style={{
                  padding: "0 15px",
                  textAlign: "justify",
                  textJustify: "auto",
                }}
              >
                <li className="my-1 font-weight-400 font-14">
                  {" "}
                 {t('GRAB_TNC_I')}{" "}
                </li>
                <li className="my-1">
                  {t('GRAB_TNC_II')}{" "}
                </li>
                <li className="my-1">
                  {t("GRAB_TNC_III")}{" "}
                  <span style={{ color: "red" }}>{t('31 May 2024')}</span>.{" "}
                </li>
                <li className="my-1">
                  {t('GRAB_TNC_IV')}{" "}
                </li>
                <li className="my-1">
                  {t('GRAB_TNC_V')}
                </li>
                <li className="my-1">
                 {t('GRAB_TNC_VI')}{" "}
                </li>
              </ul>
            )}

            <Button
              type="primary"
              className="mt-1"
              onClick={() => setTnc(false)}
              style={{
                fontSize: "18px",
                borderRadius: "8px",
                background: "#CB0E07",
                color: "white",
                border: "none",
                fontWeight: "500",
                height: "40px",
                width: "90%",
              }}
            >
              {t('Close')}
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default TnC;
