export const resizeByPercentageOfLengthInPx = (
  defaultSize = 10,
  length = 1,
  percentage = 50
) => {
  const reductionPercentage = percentage; // Percentage to reduce the size

  if (length > 1) {
    const newSize = defaultSize - (reductionPercentage / 100) * defaultSize;
    return `${newSize}px`;
  } else {
    return `${defaultSize}px`;
  }
};
export const toCamelCase = (inputString) => {
  return inputString.replace(/\s+(\w)/g, (_, char) => char.toUpperCase());
};
