import React, { useEffect, useState } from "react";
import { Button, Form, Input, Checkbox, message } from "antd";
import "../css/Auth.css";
import Success from "../components/Modal/Success";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useLocalStorage } from "@uidotdev/usehooks";
import axiosClient from "../utils/axiosClient";
import StoreChannelValidateAndRequiredModal from "../components/StoreChannelValidateAndRequiredModal/StoreChannelValidateAndRequiredModal";
import channel1 from "../pdf/Haleon CNY 2024 (MY)_[Nationwide]Consumer TC_Haleon Start a Healthy New Year.pdf";
import channel2 from "../pdf/Haleon CNY 2024_[Watsons]Consumer TC_Haleon Start a Healthy New Year.pdf";
import channel3 from "../pdf/Haleon CNY 2024 (MY)_[Guardian]Consumer TC_Haleon Start a Healthy New Year.pdf";
import channel4 from "../pdf/Haleon CNY 2024 (MY)_[Chain IP _ Ecomm]Consumer TC_Haleon Start a Healthy New Year.pdf";
import primaryBareBgImg from "../images/primary-bare_bg.png";
import primaryFrameImg from "../images/primary_frame.png";
import HeadingTitle from "../components/HeadingTitle/HeadingTitle";
import slogan from "../images/slogan.png";

const Register = ({ t }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [proceed, setProcced] = useState(false);
  const [form] = Form.useForm();
  const [referId] = useLocalStorage("referCode");
  const [tempOtp, setTempOtp] = useLocalStorage("tempOtp");
  const [loading, setLoading] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [error, setError] = useState(false);
  const [, setFullName] = useState("");
  const [showFullNameAsterisk, setShowFullNameAsterisk] = useState(true);

  const [showChannelRequiredModal, setShowChannelRequiredModal] =
    useState(false);

  const [searchParams] = useSearchParams();
  const routeChannel = searchParams.get("channel");
  const storeId = searchParams.get("storeId");
  const getLanguage = localStorage.getItem("language");

  // auto set phone number if from login
  useEffect(() => {
    if (location.state?.phone) {
      form.setFieldsValue({ phone: "60"+location.state?.phone });
    }
  }, []);

  const validateChannel = async (channel) => {
    axiosClient
      .get(`/public/haleon-cny/campaigns/${channel}/validity`)
      .then((res) => {
        if (!res.data?.isValid) {
          setShowChannelRequiredModal(true);
        }
      })
      .catch((err) => {
        setShowChannelRequiredModal(true);
      });
  };

  useEffect(() => {
    if (routeChannel) {
      validateChannel(routeChannel);
    } else {
      setShowChannelRequiredModal(true);
    }
  }, []);

  const [pdfLink, setPdfLink] = useState();

  useEffect(() => {
    const getTnC = () => {
      if (routeChannel === "watsons") {
        setPdfLink(channel2);
      } else if (routeChannel === "guardian") {
        setPdfLink(channel3);
      } else if (
        routeChannel === "shopee" ||
        routeChannel === "lazada" ||
        routeChannel === "aapharmacy" ||
        routeChannel === "bigpharmacy" ||
        routeChannel === "caring"
      ) {
        setPdfLink(channel4);
      } else {
        setPdfLink(channel1);
      }
    };
    getTnC();
  }, [routeChannel]);

  const handleSubmit = async (values) => {
    setLoading(true);

    try {
      // Check if promoter code is provided
      if (values.promoterCode) {
        const checkPromoterCode = await axiosClient.get(
          "/public/promoters?promoterCode=" + values.promoterCode,
          {
            promoterCode: values.promoterCode,
          }
        );

          if (!checkPromoterCode.data || checkPromoterCode.data.length === 0 || !checkPromoterCode.data[0]?.id) {
          message.error(t("promoter id not found"));
          return;
        }

        if (checkPromoterCode.data.length > 0) {
          const register = {
            name: values.name,
            phone: values.phone,
            email: values.email,
            companyId: process.env.REACT_APP_COMPANY_ID,
            storeId: storeId ? storeId : null,
            referralCode: referId ? referId : null,
            otp: tempOtp,
            channel: routeChannel ? String(routeChannel).toUpperCase() : null,
            registeredByPromoterId: checkPromoterCode.data[0].id,
          };
        const res = await axiosClient.post("/auth/register-otp", register);
          if (res?.data?.success && res?.data?.token) {
            setTempOtp(null);
            localStorage.setItem("user-token", res.data.token);
            setProcced(true);
          }

          if (res?.data?.message) {
            message.error(res.data.message);
          }
        } 
        else {
          message.error(t("Invalid promoter code"));
          throw new Error("");
        }
      } else {
        const register = {
          name: values.name,
          phone: values.phone,
          email: values.email,
          companyId: process.env.REACT_APP_COMPANY_ID,
          storeId: storeId ? storeId : null,
          referralCode: referId ? referId : null,
          otp: tempOtp,
          channel: routeChannel ? String(routeChannel).toUpperCase() : null,
          promoterCode: "",
        };

        const res = await axiosClient.post("/auth/register-otp", register);
        if (res?.data?.success && res?.data?.token) {
          setTempOtp(null);
          localStorage.setItem("user-token", res.data.token);
          setProcced(true);
        }

        if (res?.data?.message) {
          message.error(res.data.message);
        }
      }
    } catch (err) {
      const errorMessage = err?.res?.data?.message;
      message.error(errorMessage);
      setLoading(false);
      setError(errorMessage);
      setErrorModal(true);
      // if (err.response && err.response.data.error === "Number Registered") {}
      // console.log(err.response.data.error)
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;

    if (event.target.name === "name") {
      setFullName(value);
      setShowFullNameAsterisk(!value); // Show asterisk only if value is empty
    }
  };

  // if (!formConfig) return null;

  const regexName = /^[a-zA-Z ]+( [a-zA-Z ]+)*$/;
  const regexPhoneNum = /^(601[0-9]{8,9})$/;

  return (
    <div
      style={{
        width: "100%",
        minWidth: "280px",
        minHeight: "100vh",
        backgroundImage: `url(${primaryBareBgImg})`,
        backgroundSize: "100%",
        backgroundRepeat: "repeat",
        backgroundColor: "red",
        backgroundPosition: "top center",
        position: "relative",
        paddingBottom: "auto",
        textAlign: "center",
        zIndex: "1",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${primaryFrameImg})`,
          backgroundSize: "92%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "absolute",
          zIndex: "-1",
        }}
      />
      <StoreChannelValidateAndRequiredModal
        open={showChannelRequiredModal}
        onCancel={() => navigate("/login" + window.location.search)}
      />
      <HeadingTitle />
      <img
        src={slogan}
        alt="slogan"
        style={{ width: "100%", zIndex: "1000" }}
      />
      <div>
        <h1 className="title-font">{t("Registration")}</h1>
        <hr className="underline-style" />
        <Form
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={(e) => console.error("submit fail", e.errorFields)}
          disabled={loading}
          aria-label="form"
          autoComplete="off"
          scrollToFirstError
          style={{ width: "80%", margin: "5% auto" }}
        >
          <Form.Item
            name="name"
            className="auth-text-color"
            hasFeedback
            rules={[
              {
                required: true,
                message: "this is required",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && value?.length > 0) {
                    if (regexName.test(value)) {
                      return Promise.resolve(true);
                    }
                    return Promise.reject(new Error(t("Invalid name format!")));
                  }
                  return Promise.resolve(true);
                },
              }),
            ]}
            style={{ marginBottom: "3%" }}
          >
            <div className="input-wrapper">
              <Input
                type="text"
                name="name"
                placeholder={t("FullName")}
                className="form-input"
                style={{ color: "red", height: "40px", borderRadius: "10px" }}
                required={true}
                onChange={handleInputChange}
              />
              {showFullNameAsterisk && (
                <label
                  className={
                    getLanguage === "BM"
                      ? "bm-full-name-asterisk"
                      : getLanguage === "ENG"
                      ? "eng-full-name-asterisk"
                      : "ch-full-name-asterisk"
                  }
                ></label>
              )}
            </div>
          </Form.Item>

          <Form.Item
            name="phone"
            className="auth-text-color"
            validateDebounce={500}
            hasFeedback
            style={{ marginBottom: "3%" }}
            rules={[
              {
                required: true,
                message: "this is required",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && value?.length > 0) {
                    if (regexPhoneNum.test(value)) {
                      return Promise.resolve(true);
                    }
                    return Promise.reject(
                      new Error(t("Invalid phone number format!"))
                    );
                  }
                  return Promise.resolve(true);
                },
              }),
            ]}
          >
            <Input
              type="tel"
              name="phone"
              className="form-input"
              placeholder={t("Mobile Number")}
              style={{ color: "red", height: "40px", borderRadius: "10px" }}
            />
          </Form.Item>

          <Form.Item
            name="email"
            className="auth-text-color"
            validateDebounce={500}
            style={{ marginBottom: "3%" }}
            hasFeedback
            rules={[{ type: "email", message: t("Invalid email format!") }]}
          >
            <Input
              type="email"
              name="email"
              placeholder={t("Email")}
              style={{ color: "red", height: "40px", borderRadius: "10px" }}
            />
          </Form.Item>

          <Form.Item
            name="promoterCode"
            className="auth-text-color"
            style={{ marginBottom: "3%" }}
            validateDebounce={500}
            hasFeedback
            rules={[
              {
                validator(_, value) {
                  if (value && value.length > 0) {
                    if (/^[a-zA-Z0-9]*$/.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("Only numbers and alphabets are allowed."))
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              type="text"
              name="promoterCode"
              placeholder={t("promoterCode")}
              style={{ color: "red", height: "43px", borderRadius: "10px" }}
            />
          </Form.Item>

          <div
            className="text-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Checkbox
              required
              className="t-white"
              style={{
                position: "relative",
                bottom:
                  getLanguage === "BM"
                    ? "6.5px"
                    : getLanguage === "ENG"
                    ? "8px"
                    : "none",
              }}
            />
            {(getLanguage === "BM" || getLanguage === "ENG") && (
              <p
                className={getLanguage === "BM" ? "font-12" : "font-14"}
                style={{
                  lineHeight: "18px",
                  fontWeight: "400",
                  maxWidth: "100%",
                  textAlign: "justify",
                  margin: "auto",
                  color: "white",
                  marginLeft: "10px",
                }}
              >
                {t("I agree to Haleon's")}{" "}
                <a
                  className="t-white"
                  href={pdfLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "underline" }}
                >
                  {t("TnC")}
                </a>{" "}
                {getLanguage === "BM" && "Haleon "}
                {t("and the")}{" "}
                <a
                  className="t-white text-deco-underline"
                  target="_blank"
                  href="https://www.privacy.haleon.com/en-my/general/general-full-text/"
                  rel="noreferrer"
                  style={{ textDecoration: "underline" }}
                >
                  {t("PrivacyPolicy")}
                </a>
                .
              </p>
            )}
            {getLanguage === "CH" && (
              <p
                className={"font-14"}
                style={{
                  lineHeight: "18px",
                  fontWeight: "400",
                  maxWidth: "100%",
                  textAlign: "justify",
                  margin: "auto",
                  color: "white",
                  marginLeft: "10px",
                }}
              >
                {t("I agree to Haleon's")}
                <a
                  className="t-white"
                  href={pdfLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "underline" }}
                >
                  {t("TnC")}
                </a>
                {t("and the")}
                <a
                  className="t-white text-deco-underline"
                  target="_blank"
                  href="https://www.privacy.haleon.com/en-my/general/general-full-text/"
                  rel="noreferrer"
                  style={{ textDecoration: "underline" }}
                >
                  {t("PrivacyPolicy")}
                </a>
                .
              </p>
            )}
          </div>
          <div style={{ marginTop: "3%" }}>
            <div
              className="text-center"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Checkbox
                required
                className="t-white"
                style={{
                  position: "relative",
                  bottom: getLanguage === "BM" ? "1.35rem" : "8px",
                }}
              />
              <p
                className={getLanguage === "BM" ? "font-12" : "font-14"}
                style={{
                  lineHeight: "14px",
                  fontWeight: "400",
                  maxWidth: "100%",
                  textAlign: "justify",
                  margin: "auto",
                  color: "white",
                  marginLeft: "10px",
                }}
              >
                {t("statement")}
              </p>
            </div>
          </div>

          <Form.Item>
            <Button
              htmlType="submit"
              block
              // loading={loading}
              style={{
                borderRadius: "5px",
                background: "#63BC46",
                border: "none",
                color: "white",
                height: "40px",
              }}
              className="font-16 font-weight-700 mt-3"
            >
              {t("Register")}
            </Button>
          </Form.Item>
        </Form>
        <Success
          isModalOpen={proceed}
          setIsModalOpen={setProcced}
          title={t("Successful")}
          msg={t("Registration Successful")}
          location={`/home${window.location.search}`}
        />
      </div>
    </div>
  );
};

export default Register;
