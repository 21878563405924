import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Home from "./view/Home";
import LogoutBtn from "./components/LogoutBtn/LogoutBtn";
import Login from "./view/Login";
import Register from "./view/Register";
import OtpVerify from "./view/OtpVerify";
import Rewards from "./view/Rewards";
import Upload from "./view/Upload";
import { useDispatch } from "react-redux";
import { userLogout } from "./redux/user/userActions";
import LandingPage from "./view/LandingPage";
import AuthRoute from "./components/AuthRoute/AuthRoute";
import NotFoundPage from "./view/NotFoundPage";
import ShowStayTunedHaleonRewardsModalMaybe from "./components/StayTunedHaleonRewardsModal/StayTunedHaleonRewardsModal";
import LanguageToggleBtn from "./components/LanguageToggleBtn/LanguageToggleBtn";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../src/utilities/i18n";
import Submissions from "./view/Submissions";
const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const displayLogOutBtn = [
    "/home",
    "/rewards",
    "/upload",
    "/submissions",
  ].includes(location.pathname);
  const displayLanguageToggle = !["/register", "/verify-otp"].includes(
    location.pathname
  );
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "ENG"
  );

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      setLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    } else {
      localStorage.setItem("language", "ENG");
      i18n.changeLanguage("ENG");
    }
  }, [language]);

  return (
    <div
      className="App"
      style={{
        position: "relative",
      }}
    >
      {displayLanguageToggle && (
        <div
          style={{
            position: "absolute",
            top: "35px",
            right: location.pathname === "/login" ? "20px" : "65px",
            zIndex: 1000,
          }}
          onClick={() => {
            switch (language) {
              case "ENG":
                setLanguage("BM");
                localStorage.setItem("language", "BM");
                i18n.changeLanguage("BM");
                break;
              case "BM":
                setLanguage("CH");
                localStorage.setItem("language", "CH");
                i18n.changeLanguage("CH");

                break;
              case "CH":
              default:
                setLanguage("ENG");
                localStorage.setItem("language", "ENG");
                i18n.changeLanguage("ENG");
                break;
            }
          }}
        >
          <LanguageToggleBtn language={language} setLanguage={setLanguage} />
        </div>
      )}
      {/* LOGOUT BTN */}
      {displayLogOutBtn && (
        <div
          style={{
            position: "absolute",
            top: "35px",
            right: "20px",
            zIndex: 1000,
          }}
          onClick={() => {
            dispatch(userLogout());
            navigate("/login");
          }}
        >
          <LogoutBtn />
        </div>
      )}

      <ShowStayTunedHaleonRewardsModalMaybe />

      <Routes>
        <Route exact path="/welcome" element={<LandingPage />} />
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/login" element={<Login t={t} language={language} />} />
        <Route exact path="/register" element={<Register t={t} />} />
        <Route exact path="/verify-otp" element={<OtpVerify t={t} />} />
        <Route
          exact
          path="/home"
          element={
            <AuthRoute>
              <Home t={t} />
            </AuthRoute>
          }
        />
        <Route
          exact
          path="/upload"
          element={
            <AuthRoute>
              <Upload t={t} />
            </AuthRoute>
          }
        />
        <Route
          exact
          path="/rewards"
          element={
            <AuthRoute>
              <Rewards t={t} />
            </AuthRoute>
          }
        />
        <Route
          exact
          path="/submissions"
          element={
            <AuthRoute>
              <Submissions t={t} />
            </AuthRoute>
          }
        />

        {/* PAGE NOT FOUND */}
        <Route path="/404" element={<NotFoundPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export default App;
