import { Button } from "antd";
import goldButton from "./assets/goldButton.svg";

import calsourceBrand from "./assets/brands/calsource.png";
import caltrateBrand from "./assets/brands/caltrate.png";
import centrumBrand from "./assets/brands/centrum.png";
import enoBrand from "./assets/brands/eno.png";
import panaflexBrand from "./assets/brands/panaflex.png";
import parodontaxBrand from "./assets/brands/parodontax.png";
import polidentBrand from "./assets/brands/polident.png";
import scottsBrand from "./assets/brands/scotts.png";
import sensodyneBrand from "./assets/brands/sensodyne.png";

import angPao from "./assets/ang-pao.png";
import angPaoBg from "./assets/ang-pao_bg.png";
import footerBrandSvg from "./assets/footerBrandBg.svg";
import React from "react";
import HelpDeskHover from "../HelpDeskHover/HelpDeskHover";
import { useWindowSize } from "@uidotdev/usehooks";
import BrandList from "./BrandList";
import { resizeByPercentageOfLengthInPx } from "../../utils/helper";
import EnvelopHeaderRewardAmount from "./EnvelopHeaderRewardAmount";

import { HALEON_CNY_CAMPAIGNS } from "../../consts/haleonCnyCampaignsChannels";
import { useTranslation } from "react-i18next";

export default function EWalletCreditRedeem({ campaign, onClickRedeemNow }) {
  const { t } = useTranslation();
  const getLanguage = localStorage.getItem("language");
  const windowWidth = useWindowSize().width;

  const BRANDS_IMAGES_ROW_1 = [
    { src: panaflexBrand, alt: "panaflex Brand logo" },
    { src: sensodyneBrand, alt: "sensodyne Brand logo" },
    { src: polidentBrand, alt: "polident Brand logo" },
    { src: scottsBrand, alt: "scott's Brand logo" },
  ];

  const BRANDS_IMAGES_ROW_2 = [
    { src: centrumBrand, alt: "centrum Brand logo" },
    { src: caltrateBrand, alt: "caltrate Brand logo" },
    { src: parodontaxBrand, alt: "parodontax Brand logo" },
    { src: calsourceBrand, alt: "calsource Brand logo" },
    { src: enoBrand, alt: "eno Brand logo" },
  ];

  const nationWideEWallets = [
    {
      reward: {
        types: ["TNG", "GRAB", "SPAY"],
        amount: "RM5",
        description: "E-Voucher",
      },
      periodComponent: (
        <>
          {getLanguage === "ENG" && (
            <>
              1<sup>st</sup>January - 29<sup>th</sup> February 2024
            </>
          )}
          {getLanguage === "BM" && (
            <>
              1<sup>st</sup>Januari - 29<sup>th</sup> Februari 2024
            </>
          )}
          {getLanguage === "CH" && <>2024年1月1日至2月29日</>}
        </>
      ),
      minPurchase: "RM38",
    },
  ];

  const watsonEWallets = [
    {
      reward: {
        type: "TNG",
        amount: "RM8.80",
        description: "RELOAD PIN",
      },
      periodComponent: (
        <>
          {getLanguage === "ENG" && (
            <>
              1<sup>st</sup>January - 29<sup>th</sup> February 2024
            </>
          )}
          {getLanguage === "BM" && (
            <>
              1<sup>st</sup>Januari - 29<sup>th</sup> Februari 2024
            </>
          )}
          {getLanguage === "CH" && <>2024年1月1日至2月29日</>}
        </>
      ),
      minPurchase: "RM68",
    },
    {
      reward: {
        type: "TNG",
        amount: "RM16.80",
        description: "RELOAD PIN",
      },
      periodComponent: (
        <>
          {getLanguage === "ENG" && (
            <>
              1<sup>st</sup>January - 29<sup>th</sup> February 2024
            </>
          )}
          {getLanguage === "BM" && (
            <>
              1<sup>st</sup>Januari - 29<sup>th</sup> Februari 2024
            </>
          )}
          {getLanguage === "CH" && <>2024年1月1日至2月29日</>}
        </>
      ),
      minPurchase: "RM128",
    },
  ];

  const guardianEwallets = [
    {
      reward: {
        type: "TNG",
        amount: "RM8.80",
        description: "RELOAD PIN",
      },
      periodComponent: (
        <>
          {getLanguage === "ENG" && (
            <>
              27<sup>th</sup>Dec - 12<sup>th</sup>Feb 2024
            </>
          )}
          {getLanguage === "BM" && (
            <>
              27<sup>th</sup>Dis - 12<sup>th</sup>Feb 2024
            </>
          )}
          {getLanguage === "CH" && <>2023年12月27日至2月12日</>}
        </>
      ),
      minPurchase: "RM68",
    },
  ];

  const cbaplsEWallets = [
    {
      reward: {
        type: "TNG",
        amount: "RM8.80",
        description: "RELOAD PIN",
      },
      periodComponent: (
        <>
          {getLanguage === "ENG" && (
            <>
              1<sup>st</sup>January - 29<sup>st</sup> January 2024
            </>
          )}
          {getLanguage === "BM" && (
            <>
              1<sup>st</sup>Januari - 29<sup>st</sup> Januari 2024
            </>
          )}
          {getLanguage === "CH" && <>2024年1月1日至1月29日</>}
        </>
      ),
      minPurchase: "RM68",
    },
  ];

  const eWalletsBasedOnCampaignId = {
    [HALEON_CNY_CAMPAIGNS.NATIONWIDE]: nationWideEWallets,
    [HALEON_CNY_CAMPAIGNS.WATSONS]: watsonEWallets,
    [HALEON_CNY_CAMPAIGNS.GUARDIAN]: guardianEwallets,
    [HALEON_CNY_CAMPAIGNS.CBALS]: cbaplsEWallets,
  };

  const ewalletCampaign = eWalletsBasedOnCampaignId?.[campaign?.id] || [];

  const length = windowWidth <= 350 ? 2 : ewalletCampaign.length;
  const resizeByLength = (defaultSize) =>
    resizeByPercentageOfLengthInPx(defaultSize, length, 30);

  const resizeByLengthWalletHeader = (defaultSize) =>
    resizeByPercentageOfLengthInPx(defaultSize, length, 32);

  return (
    <div
      style={{
        width: "100%",
        minWidth: "280px",
        height: "100%",

        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",

        overflow: "hidden",
      }}
    >
      <div
        style={{
          backgroundwidth: "100%",
          backgroundImage: `url(${footerBrandSvg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          backgroundSize: "cover",
          alignContent: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          flexDirection: "column",
          minHeight: "260px",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${angPaoBg})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom center",
            backgroundSize: "100% 100%",
            width: "100%",
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
          }}
        >
          {/* HELP DESK HOVER */}
          <HelpDeskHover />

          {/* ENVELOPS */}
          <div
            style={{
              width: "90%",
              height: "auto",
              display: "flex",
              alignItems: "center",
              flexDirection: windowWidth < 350 ? "column" : "row",
            }}
          >
            {React.Children.toArray(
              ewalletCampaign.map((ewallet) => (
                <div
                  style={{
                    flex: 1,
                    margin: "0 -10%",

                    position: "relative",
                    width: "100%",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      maxWidth: "360px",
                      width: "100%",
                    }}
                    src={angPao}
                    alt="E-Wallet Envelop"
                  />

                  {/* CONTENT */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",

                      width: "70%",
                      height: "85%",

                      position: "absolute",
                      zIndex: 100,
                    }}
                  >
                    {/* REWARD AMOUNT */}
                    <EnvelopHeaderRewardAmount
                      reward={ewallet.reward}
                      resizeByLengthFunc={resizeByLengthWalletHeader}
                    />

                    {/* DETAILS */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "#FBFAFC",
                          fontFamily: "Hero New",
                          fontSize: resizeByLength(14),
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "normal",

                          margin: "0",
                        }}
                      >
                        {t("CampaignPeriod")}
                        <br />
                        {ewallet.periodComponent}
                      </p>

                      <h4
                        style={{
                          color: "#FBFAFC",
                          fontFamily: "Hero New",
                          fontSize: resizeByLength(18),
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "normal",

                          margin: "2%",
                        }}
                      >
                        {t("Min Purchase of")} {ewallet.minPurchase}
                      </h4>

                      <Button
                        onClick={onClickRedeemNow}
                        style={{
                          backgroundImage: `url(${goldButton})`,
                          backgroundOrigin: "center",
                          backgroundSize: "100% 100%",
                          backgroundRepeat: "no-repeat",
                          backgroundColor: "transparent",
                          border: "none",

                          height: "100%",
                          width: getLanguage === "CH" ? "60%" : "fit-content",

                          fontSize: resizeByLength(
                            getLanguage === "BM" ? 15 : 18
                          ),
                          letterSpacing: "0.02em",
                          fontWeight: "800",
                          color: "#fe5640",
                          padding: "12px 8px",
                          pointerEvents: "all",
                        }}
                      >
                        {t("RedeemNow")}
                      </Button>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        {/* Brands */}
        <div
          style={{
            width: "90%",
            margin: "0 auto",
          }}
        >
          <BrandList brands={BRANDS_IMAGES_ROW_1} />
          <BrandList brands={BRANDS_IMAGES_ROW_2} />
        </div>
      </div>
    </div>
  );
}
